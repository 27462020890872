import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { getSmsLogs } from "../../../../../redux/slices/sms_slice_log";
import CustomPageHeader from "../../../components/custom_page_header";
export default function SmsLogs() {
    const dispatch = useDispatch();

    // const [startDate, setStartDate] = useState(new Date().getFullYear().toString() + "-" + new Date().getMonth().toString().padStart(2, 0) + "-" + new Date().getDate().toString().padStart(2, 0));
    const [startDate, setStartDate] = useState(new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0"));
    const [endDate, setEndDate] = useState(new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString().padStart(2, 0) + "-" + new Date().getDate().toString().padStart(2, 0));

    //Redux Selector
    const SmsLogsReducer = useSelector((state) => state.smsLogs);

    const { data, count, today, thisMonth } = SmsLogsReducer;
    useEffect(() => {
        dispatch(getSmsLogs());
    }, []);

    useEffect(() => {
        dispatch(getSmsLogs({ startDate, endDate }));
    }, [endDate, startDate]);

    const DateTemplate = (rowData) => {
        return <>{moment(rowData?.dateAndTime).format("MMMM Do YYYY, h:mm a")}</>;
    };

    const mobileBodyTemplate = (rowData) => {
        console.log("rowData", rowData);
        return rowData ? `+${rowData.phoneNumber}` : "N/A";
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-7 flex">
                    <CustomPageHeader title={"SMS Logs"} />
                </div>

                <div className="col-12 md:col-5 text-right">
                    <div className="both__date">
                        <InputText value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" className="date__input" />

                        <InputText value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" className="date__input" />
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0 smsLog_card">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Sent</span>
                                <div className="text-900 font-medium text-xl">{[count]}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-envelope text-blue-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0 smsLog_card">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">This Month Sent</span>
                                <div className="text-900 font-medium text-xl">{[thisMonth]}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-envelope text-blue-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0 smsLog_card">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Today Sent</span>
                                <div className="text-900 font-medium text-xl">{[today]}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-envelope text-blue-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <DataTable filter rows={12} paginator responsiveLayout="scroll" value={data}>
                    <Column className="maker_colm" body={mobileBodyTemplate} header="Phone number"></Column>
                    <Column className="maker_colm" body={DateTemplate} header=" Time Lapsed"></Column>
                    <Column className="maker_colm" field="message" header=" message"></Column>
                </DataTable>
            </div>
        </>
    );
}
