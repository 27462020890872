import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { addAds, editAds, getAdsList, resetAddEditDeleteAds } from "../../../../../redux/slices/ads_slice";
import { InputText } from "primereact/inputtext";
import appUrl from "../../../constants/appUrl"; // Import appUrl

export default function AddEditAdDialog({ onHide, editCode, permissions, getAdsList }) {
    const adsReducer = useSelector((state) => state.adsList);
    const { addLoading, addError, editLoading, editError, addSuccess, editSuccess } = adsReducer;

    console.log("editCode", editCode);
    const dispatch = useDispatch();
    const [imageUrl, setAdImage] = useState();
    const [newImageSelected, setNewImageSelected] = useState(false); // Tracks if a new image has been chosen

    const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required("This field is required.")
            .required("This field is required.")
            .matches(/^[^\d]*$/, "Title cannot contain numbers.")
            .matches(/^[^\s].*[^\s]$/, "Title cannot start or end with spaces.")
            .max(30, "Title cannot be longer than 30 characters."),
        redirectUrl: Yup.string().matches(re, "Please add valid url").required("This field is required."),
        buttonText: Yup.string().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            title: "",
            redirectUrl: "",
            buttonText: "",
            imageUrl: imageUrl,
        },
        onSubmit: async (data) => {
            const adData = {
                ...data,
                image: imageUrl,
            };

            if (!editCode) {
                // Validate the image format only if a new image is selected or if editing requires a valid image
                if ((!editCode || newImageSelected) && (!imageUrl || !imageUrl.startsWith("data:image"))) {
                    toast.error("Please upload a valid image file before submitting.");
                    return;
                }
                dispatch(addAds(adData));
            } else {
                adData.id = editCode._id;
                // Validate the image only if a new one has been selected
                if (newImageSelected && (!imageUrl || !imageUrl.startsWith("data:image"))) {
                    toast.error("Please upload a valid image file before submitting.");
                    return;
                }
                dispatch(editAds(adData));
            }
            dispatch(getAdsList({ pageNumber: 0, text: "" }));
        },
    });

    useEffect(() => {
        if (addSuccess) {
            toast.success("Advertisement successfully added");
            onHide();
            dispatch(getAdsList({ pageNumber: 0, text: "" }));
            // dispatch(getAdsList());
        } else if (addError) {
            toast.error(addError);
        }

        if (editSuccess) {
            toast.success("Advertisement successfully updated");
            onHide();
            dispatch(getAdsList({ pageNumber: 0, text: "" }));
            // dispatch(getAdsList());
        } else if (editError) {
            toast.error(editError);
        }

        return () => {
            dispatch(resetAddEditDeleteAds());
        };
    }, [addSuccess, addError, editSuccess, editError]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Check if file exists and is an image
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setAdImage(e.target.result);
                setNewImageSelected(true);
            };
            reader.readAsDataURL(file);
        } else {
            toast.error("Please upload a valid image file.");
            setNewImageSelected(true);
            setAdImage(null);
        }
    };

    useEffect(() => {
        if (editCode) {
            loadInitialValues();
            setNewImageSelected(false);
            setAdImage(editCode.image);
        }
    }, [editCode]);

    useEffect(() => {
        if (editCode != null) {
            loadInitialValues();
        }
    }, []);

    const loadInitialValues = () => {
        formik.setFieldValue("title", editCode.title);
        formik.setFieldValue("redirectUrl", editCode.redirectUrl);
        formik.setFieldValue("buttonText", editCode.buttonText);
        formik.setFieldValue("imageUrl", editCode.imageUrl); // Set the imageUrl field
        setAdImage(editCode.imageUrl);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="col-12 md:col-6 p-fluid">
                    <label style={{ marginLeft: "25px", marginBottom: "10px", display: "flex" }}>Image</label>
                    <input style={{ width: "90%", marginLeft: "24px" }} id="imageUrl" name="imageUrl" type="file" accept="image/*" onChange={handleFileChange} />
                    {imageUrl && <img src={imageUrl.startsWith("data:image") ? imageUrl : `${appUrl.fileBaseUrl}${imageUrl}`} alt="Ad Preview" style={{ width: "100px", height: "100px", marginTop: "10px", marginLeft: "25px" }} />} {/* Use fileBaseUrl */}
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="title" label="Title" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="redirectUrl" label="Redirect URL" formik={formik} type="text" />
                </div>
                <div className="col-12 md:col-6 p-fluid">
                    <CustomInputField iden="buttonText" label="Button Text" formik={formik} type="text" />
                </div>
                {/* <div className="col-12 p-fluid">
                    <div className="button_class-modal">{<Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                    <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                </div> */}
                <div className="col-12 p-fluid">
                    <div className="button_class-modal">{permissions?.edit && editCode !== null && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                    <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                </div>
            </div>
        </form>
    );
}
