import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { axiosApi } from "../../../constants/axios_api";
import appUrl from "../../../constants/appUrl";

function AddCorporateAdminDialog({ onHideCorporateAdminDialog }) {
    const [loading, setLoading] = useState(false);
    const [profileImage, setProfileImage] = useState("");
    const [corporateList, setCorporateList] = useState();
    const [roleLovData, setRoleLovData] = useState();

    const validationSchema = Yup.object().shape({
        userName: Yup.string().matches(/^(?=.*[a-zA-Z0-9])[\w!@#$%^&*()_+=\-?.,]+$/, "Cannot contain only spaces or special characters.").max(35, "Maximum length 35 allowed").required("This field is required."),
        password: Yup.string()
            .required("This field is required.")
            .min(8, "Minimum length should be 8")
            .max(50, "Maximum length 50 allowed")
            .matches(/^(?=.*[a-zA-Z0-9])[\w!@#$%^&*()_+=\-?.,]+$/, "Password cannot contain only spaces or special characters."),
        // Yup.string().max(50, "Maximum length 50 allowed").required("This field is required.").min(8, "Minimum length should be 8"),

        email: Yup.string()
            .email("Invalid email address format")
            .required("This field is required.")
            .matches(/@rahper\.com$/, "Input must end with @rahper.com"),
        mobile: Yup.string()
            .required("This field is required.")
            .matches(/^03[0-9]{9}$/, "Number should start with 03 and length should be 11")
            .max(11, "Maximum length 11 allowed")
            .nullable(),
        role: Yup.string().required("Role is required"),
        corporate: Yup.string().required("This field is required."),
        profileImage: Yup.string().required("Profile image is required.").nullable(),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            userName: "",
            password: "",
            email: "",
            mobile: "",
            role: "",
            corporate: "",
            profileImage: "",
        },
        onSubmit: async (data) => {
            const payload = {
                userName: data.userName,
                password: data.password,
                email: data.email,
                mobile: data.mobile,
                role: data.role,
                corporate: data.corporate,
                profileImage: data.profileImage,
            }
            try {
                const response = await axiosApi.post(`${appUrl?.baseUrl}api/web/adminUser`, payload);
                if (response?.status === 200) {
                    toast.success(response?.data?.msg);
                    onHideCorporateAdminDialog();
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                onHideCorporateAdminDialog();
            }
        },
    });
    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();

    //     reader.onload = (e) => {
    //         setProfileImage(e.target.result);
    //     };

    //     reader.readAsDataURL(file);
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type.split("/")[0];
            if (fileType === "image") {
                const reader = new FileReader();
                reader.onload = (e) => {
                    formik.setFieldValue("profileImage", e.target.result);
                    formik.setFieldError("profileImage", "");
                };
                reader.readAsDataURL(file);
            } else {
                formik.setFieldError("profileImage", "Please upload a valid image file.");
                event.target.value = "";
            }
        } else {
            formik.setFieldValue("profileImage", "");
        }
    };

    const getallCorporates = async () => {
        const response = await axiosApi.get(`${appUrl.baseUrl}api/corporate/corporateLov`);
        setCorporateList(response?.data?.data);
    };
    const getallRoles = async () => {
        const response = await axiosApi.get(`${appUrl.baseUrl}api/web/role/corporateAll`);
        setRoleLovData(response?.data?.data);
    };

    useEffect(() => {
        getallCorporates();
        getallRoles();
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid ">
                    <div className="col-12 ">
                        <div className="p-fluid grid">
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label>Username</label>
                                    <InputText name="userName" id="userName" value={formik.values.userName || ""} onChange={formik.handleChange} />
                                    {getFormErrorMessage("userName")}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label>Password</label>
                                    <InputText type="password" name="password" id="password" value={formik.values.password || ""} onChange={formik.handleChange} />
                                    {getFormErrorMessage("password")}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label>Email</label>
                                    <InputText type="email" name="email" id="email" value={formik.values.email || ""} onChange={formik.handleChange} />
                                    {getFormErrorMessage("email")}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label>Mobile</label>
                                    <InputText keyfilter="int" name="mobile" id="mobile" value={formik.values.mobile || ""} onChange={formik.handleChange} maxLength={11} minLength={11} />
                                    {getFormErrorMessage("mobile")}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label>Role</label>
                                    <Dropdown id="role" name="role" options={roleLovData} optionLabel="role" optionValue="_id" placeholder="Select role" value={formik.values.role || ""} onChange={formik.handleChange} />
                                    {getFormErrorMessage("role")}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label>Corporate</label>
                                    <Dropdown id="corporate" placeholder="Select corporate" name="corporate" options={corporateList} optionLabel="name" optionValue="_id" value={formik.values.corporate || ""} onChange={formik.handleChange} />
                                    {getFormErrorMessage("corporate")}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label>Profile Image</label>
                                    <InputText id="profileImage" placeholder="Upload Profile Image" name="profileImage" type="file" accept="image/*" onChange={handleFileChange} />
                                    {getFormErrorMessage("profileImage")}
                                </div>
                            </div>

                            <div className="col-12 md:col-12">
                                <div className="button_class-modal">
                                    <Button type="submit" label={"Add"} className="p-button-success btn__default_modal p-button-rounded" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default AddCorporateAdminDialog;
