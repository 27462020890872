import Axios from 'axios';
import appUrl from '../../app/features/constants/appUrl';
import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const resetPasswordSlice = createSlice({
    name: 'resetPasswordSlice',
    initialState: {},
    reducers: {
        resetAddEditFaqCategory(state, action) {
            return { ...state, addSuccess: undefined, addSuccess: undefined, deleteSuccess: undefined }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetPassword.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(resetPassword.fulfilled, (state, action) => {

                return { ...state, addLoading: false, data: action.payload, addSuccess: true }
            })
            .addCase(resetPassword.rejected, (state, action) => {

                return {
                    ...state, addLoading: false,
                    error: action.payload,
                    addSuccess: false
                }
            });
    },
});

export default resetPasswordSlice.reducer;
export const { resetAddEditFaqCategory } = resetPasswordSlice.actions;



// Thunks
export const resetPassword = createAsyncThunk('resetPassword/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.resetPassword, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});

