import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const usersReducer = createSlice({
    name: "users",
    initialState: {},
    reducers: {
        resetUpdateUsers(state, action) {
            return {
                ...state,
                updateSuccess: undefined,
                //editSuccess: undefined
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsersList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getUsersList.fulfilled, (state, action) => {
                return { ...state, loading: false, users: action.payload.data, count: action.payload.count };
            })
            .addCase(getUsersList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });

        builder
            .addCase(updateUserStatus.pending, (state, action) => {
                return { ...state, updateLoading: true };
            })
            .addCase(updateUserStatus.fulfilled, (state, action) => {
                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true };
            })
            .addCase(updateUserStatus.rejected, (state, action) => {
                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false,
                };
            });
        builder
            .addCase(getUserById.pending, (state, action) => {
                return { ...state, usersLoading: true };
            })
            .addCase(getUserById.fulfilled, (state, action) => {
                return { ...state, usersLoading: false, user: action.payload };
            })
            .addCase(getUserById.rejected, (state, action) => {
                return {
                    ...state,
                    usersLoading: false,
                    usersError: action.payload,
                };
            });
        builder
            .addCase(getBlockedUserList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getBlockedUserList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload, count: action.payload };
            })
            .addCase(getBlockedUserList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
        builder
            .addCase(getAllUsersList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getAllUsersList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload, count: action.payload };
            })
            .addCase(getAllUsersList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
    },
});

export default usersReducer.reducer;
export const { resetUpdateUsers } = usersReducer.actions;

// Thunks
export const getUsersList = createAsyncThunk("users/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getDriversList + `?userType=2&pageNumber=${body.pageNumber}&text=${body.text}&perPageRecords=${body.perPageRecords}&gender=${body.gender}&status=${body.status}&loginDevice=${body.loginDevice}&corporate=${body.corporate}`);
        return fulfillWithValue({ data: data.data, count: data.count });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const updateUserStatus = createAsyncThunk("users/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.updateUserStatus, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const getUserById = createAsyncThunk("getUsersById/fetch", async ({ userId, isDriver }, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getUserById + `?userId=${userId}&isDriver=${isDriver}`);
        // //console.log("user data",data)
        return fulfillWithValue(data.user);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const getBlockedUserList = createAsyncThunk("getBlockedUsers/fetch", async (pageNumber, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getBlockedUserList);
        // console.log("blocked user", data);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const getAllUsersList = createAsyncThunk("getAllUsersList/fetch", async (pageNumber = 0, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getDriversList + `?userType=all&pageNumber=${pageNumber}`);
        // console.log("all user", data);
        return fulfillWithValue(data?.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
