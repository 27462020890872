import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomPageHeader from "../../../components/custom_page_header";
import CustomInputField from "../../../components/custom_input_filed";
import CustomLoading from "../../../components/custom_loading";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { getFares, resetUpdateFare, updateFares } from "../../../../../redux/slices/fare_management_slice";

export default function FareManagementScreen({ permissions }) {
    //redux
    const getFaresReducer = useSelector((state) => state.fareManagement);
    const { data: fare, loading, updateLoading, updateSuccess, updateError } = getFaresReducer;
    //hooks
    const dispatch = useDispatch();

    //useEffects
    useEffect(() => {
        if (fare === undefined) {
            dispatch(getFares());
        }
        return () => {
            dispatch(resetUpdateFare());
        };
    }, [dispatch, fare]);

    useEffect(() => {
        if (fare !== undefined) {
            formik.setFieldValue("petrol", fare?.fulePrice?.petrol);
            formik.setFieldValue("deisel", fare?.fulePrice?.deisel);
            formik.setFieldValue("gasoline", fare?.fulePrice?.gasoline);
            formik.setFieldValue("minCarCharges", fare?.minCarCharges);
            formik.setFieldValue("minBikeCharges", fare?.minBikeCharges);
            formik.setFieldValue("carMaintenance", fare?.carMaintenance);
            formik.setFieldValue("bikeMaintenance", fare?.bikeMaintenance);
        }
    }, [fare]);

    useEffect(() => {
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                dispatch(getFares());
                toast.success("Updated successfully!");
            } else {
                toast.error(updateError);
            }
        }
    }, [updateSuccess]);

    //forms

    const validationSchema = Yup.object().shape({
        petrol: Yup.number().required("Petrol price is required"),
        deisel: Yup.number().required("Deisel price is required"),
        gasoline: Yup.number().required("gasoline is required"),
        minCarCharges: Yup.number().required("Minimum car charges are required"),
        minBikeCharges: Yup.number().required("Maximum car charges are required"),
        carMaintenance: Yup.number().required("Rahper percent is required"),
        bikeMaintenance: Yup.number().required("Rahper percent is required"),
    });

    const isFareChanged = () => {
        if (formik.values.petrol !== fare.fulePrice.petrol) {
            return true;
        }
        if (formik.values.deisel !== fare.fulePrice.deisel) {
            return true;
        }
        if (formik.values.gasoline !== fare.fulePrice.gasoline) {
            return true;
        }
        if (formik.values.minCarCharges !== fare.minCarCharges) {
            return true;
        }
        if (formik.values.minBikeCharges !== fare.minBikeCharges) {
            return true;
        }
        if (formik.values.carMaintenance !== fare.carMaintenance) {
            return true;
        }
        if (formik.values.bikeMaintenance !== fare.bikeMaintenance) {
            return true;
        }
        return false;
    };

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            petrol: "",
            deisel: "",
            gasoline: "",
            minCarCharges: "",
            minBikeCharges: "",
            carMaintenance: "",
            bikeMaintenance: "",
        },

        onSubmit: async (data) => {
            data["initialFareId"] = fare._id;

            if (isFareChanged()) {
                dispatch(updateFares(data));
            } else {
                toast.warn("Please update some values first");
            }
        },
    });

    useEffect(() => {
        const disableScroll = (event) => {
            if (event.target.type === "number") {
                event.preventDefault();
            }
        };

        document.addEventListener("wheel", disableScroll, { passive: false });

        return () => {
            document.removeEventListener("wheel", disableScroll);
        };
    }, []);

    return (
        <>
            {loading === true || fare === undefined ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="pb-5">
                            <div className="grid">
                                <div className="col-12 md:col-7">
                                    <CustomPageHeader title={"Fare Management"} />
                                </div>
                                <div className="col-12 md:col-5 text-right">{permissions?.edit && <Button loading={updateLoading} label="Update" type="submit" className="p-button-success p-button-rounded p-button-sm custom___width" />}</div>
                            </div>
                        </div>
                        <div className="grid p-fluid">
                            <div className="col-12">
                                <h5>Fuel Price</h5>
                            </div>

                            <div className=" col-12 md:col-4">
                                <CustomInputField iden="petrol" label={"Petrol(PKR)"} formik={formik} type="number" className="field" />
                            </div>
                            <div className=" col-12 md:col-4">
                                <CustomInputField iden="deisel" label={"Diesel(PKR)"} formik={formik} type="number" className="field" />
                            </div>
                            <div className=" col-12 md:col-4">
                                <CustomInputField iden="gasoline" label={"Gasoline(PKR)"} formik={formik} type="number" className="field" />
                            </div>
                            <div className="col-12">
                                <h5>Minimum Charges</h5>
                            </div>

                            <div className=" col-12 md:col-4">
                                <CustomInputField iden="minCarCharges" formik={formik} label="Minimum Car charges" type="number" className="field" />
                            </div>
                            <div className=" col-12 md:col-4">
                                <CustomInputField iden="minBikeCharges" formik={formik} label="Minimum Bike charges" type="number" className="field" />
                            </div>
                            <div className="col-12">
                                <h5>Owners' Maintenance Percent</h5>
                            </div>

                            <div className=" col-12 md:col-4">
                                <CustomInputField iden="carMaintenance" formik={formik} label="Car Maintenance Percent" type="number" className="field" />
                            </div>
                            <div className=" col-12 md:col-4">
                                <CustomInputField iden="bikeMaintenance" formik={formik} label="Bike Maintenance Percent" type="number" className="field" />
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}
