import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInputField from "../../../components/custom_input_filed";
import * as Yup from "yup";
import { Button } from "primereact/button";
import CustomLoading from "../../../components/custom_loading";
import { toast } from "react-toastify";
import { getConfigurations, resetUpdateConfigurations, updateConfigurations } from "../../../../../redux/slices/configuration_slice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
export default function PerReferenceScreen({ permissions }) {
    //redux
    const configurationReducer = useSelector((state) => state.configuration);
    const { data: configurationsData, loading, error, success, updateData, updateLoading, updateSuccess, updateError } = configurationReducer;
    const ridePointObj = configurationsData?.ridePoint?.map((data) => {
        return {
            id: data?.id,
            point: data?.point,
            price: data?.price,
        };
    });
    const [isChanged, setIsChanged] = useState(false);
    const dispatch = useDispatch();
    const [slabs, setSlabs] = useState();
    const onRowEditComplete = async (e) => {
        let updatedSlabs = [...ridePointObj];
        const { newData, index } = e;
        const updatedIndex = updatedSlabs.findIndex((slab) => slab.id === newData.id);
        if (updatedIndex !== -1) {
            updatedSlabs[updatedIndex] = { ...updatedSlabs[updatedIndex], point: newData.point, price: newData.price };
        }
        setSlabs(updatedSlabs);
        formik.handleSubmit();
    };

    const textEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} onWheel={(e) => e.preventDefault()} />;
    };

    const priceEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="PKR" locale="en-PK" key={options.rowIndex} onWheel={(e) => e.preventDefault()} />;
    };

    const priceBodyTemplate = (rowData) => {
        return new Intl.NumberFormat("en-PK", { style: "currency", currency: "PKR" }).format(rowData.price);
    };

    // const allowEdit = (rowData) => {
    //     return rowData.name !== 'Blue Band';
    // };

    const validationSchema = Yup.object().shape({
        referPoint: Yup.number().integer("Reference Point must be an integer").min(0, "Reference Point cannot be less than 0").required("Reference Point is required"),
        point: Yup.number().integer("Ride Point must be an integer").min(0, "Ride Point cannot be less than 0").required("Ride Point is required"),
        price: Yup.number().integer("Ride Price must be an integer").min(0, "Ride Price cannot be less than 0").required("Ride Price is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            point: 0,
            price: 0,
            referPoint: 0,
        },

        onSubmit: async (data) => {
            setIsChanged(true);
            const formFieldsChanged = formik.values.point !== configurationsData?.ridePoint[0]?.point || formik.values.price !== configurationsData?.ridePoint[0]?.price;

            const slabData =
                slabs?.length > 0
                    ? slabs?.map((slab) => ({
                          point: slab?.point || 0,
                          price: slab?.price || 0,
                      }))
                    : configurationsData?.ridePoint?.map((data) => {
                          return {
                              point: data?.point || 0,
                              price: data?.price || 0,
                          };
                      });

            const payload = {
                rahperShare: configurationsData?.rahperShare || 10, // Preserve existing value
                redeemValue: configurationsData?.redeemValue || 10, // Preserve existing value
                refererPercentage: configurationsData?.refererPercentage || 0, // Preserve existing value
                referPoint: data?.referPoint || 0,
                ridePoint: [...(formFieldsChanged ? [{ point: formik?.values?.point, price: formik?.values?.price }] : []), ...(slabData && slabData?.length > 0 ? slabData : [])],
            };
            // console.log(payload,"payload")
            dispatch(updateConfigurations(payload));
            if (isChanged) {
                dispatch(getConfigurations());
            }
            setIsChanged(false);
        },
    });
    //useEffects
    useEffect(() => {
        if (configurationsData === undefined) {
            dispatch(getConfigurations());
        }
        return () => {
            dispatch(resetUpdateConfigurations());
        };
    }, [dispatch, configurationsData]);

    useEffect(() => {
        if (configurationsData !== undefined) {
            formik.setFieldValue("referPoint", configurationsData?.referPoint);
            formik.setFieldValue("point", configurationsData?.ridePoint[0]?.point || 0);
            formik.setFieldValue("price", configurationsData?.ridePoint[0]?.price || 0);
        }
    }, [configurationsData]);

    useEffect(() => {
        const hasChanges = () => {
            const normalize = (value) => (value !== undefined && value !== null ? value.toString().trim() : "");

            return normalize(formik.values.referPoint) !== normalize(configurationsData?.referPoint) || normalize(formik.values.point) !== normalize(configurationsData?.ridePoint[0]?.point) || normalize(formik.values.price) !== normalize(configurationsData?.ridePoint[0]?.price);
        };

        setIsChanged(hasChanges());
    }, [formik.values, configurationsData]);

    // useEffect(() => {
    //     if (updateSuccess !== undefined) {
    //         if (updateSuccess === true) {
    //             toast.success("Updated successfully!");
    //         } else {
    //             toast.error(updateError);
    //         }
    //     }
    // }, [updateSuccess]);
    useEffect(() => {
        // if (success !== undefined) {
        //     if (success === true) {
        //         console.log("bbb")
        //         toast.success("Latest Config Reterived!");
        //         // window.location.reload();
        //     }
        // }

        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Updated successfully!");
                dispatch(getConfigurations());
                // window.location.reload();
            } else {
                toast.error(updateError);
            }
        }
        return () => {
            dispatch(resetUpdateConfigurations());
        };
    }, [updateSuccess, updateError]);

    useEffect(() => {
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Updated successfully!");
                dispatch(getConfigurations()); // Ensure GET request is called
            } else {
                toast.error(updateError);
            }
        }
    }, [updateSuccess, updateError]);

    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="flex justify-content-end">
                        <div className="text-right">
                            {/* {permissions?.edit &&  //col-12 md:col-5 */}
                            <Button
                                // loading={updateLoading}
                                disabled={!isChanged}
                                label="Update"
                                type="submit"
                                className="p-button-success p-button-rounded p-button-sm custom___width"
                            />
                            {/* }  */}
                        </div>
                    </div>
                    <div className="grid p-fluid">
                        <div className="col-12"></div>
                        <CustomInputField iden="referPoint" formik={formik} label="Per Referal Points" type="number" className="field col-12 md:col-4" onWheel={(e) => e.preventDefault()} />
                        <CustomInputField iden="point" formik={formik} label="Per Ride Points" type="number" className="field col-12 md:col-4" onWheel={(e) => e.preventDefault()} />
                        <CustomInputField iden="price" formik={formik} label="Ride Price" type="number" className="field col-12 md:col-4" onWheel={(e) => e.preventDefault()} />
                    </div>
                    {/* <div className="grid p-fluid">
                        <CustomInputField iden="redeemValue" formik={formik} label="Redeem Value" type="text" className="field col-12 md:col-4" />
                        <div className="field col-12 md:col-4"></div>
                        <div className="field col-12 md:col-4"></div>
                    </div>
                    <div className="grid p-fluid">
                        <CustomInputField iden="rahperShare" formik={formik} label="Rahper Share" type="text" className="field col-12 md:col-4" />
                        <div className="field col-12 md:col-4"></div>
                        <div className="field col-12 md:col-4"></div>
                    </div> */}
                    {/* <div className="flex justify-content-center">
                    <div className="text-right">
                        {/* {permissions?.edit &&  //col-12 md:col-5 
                        <Button
                            // loading={updateLoading} 
                            disabled={!isChanged}
                            label="Update" type="submit" className="p-button-success p-button-rounded p-button-sm custom___width" />
                        {/* }  
                    </div>
                </div> */}

                    <div className=" p-fluid mt-4">
                        <DataTable
                            loading={false}
                            responsiveLayout="scroll"
                            value={configurationsData?.ridePoint}
                            key="_id"
                            editMode="row"
                            dataKey="id"
                            onRowEditComplete={onRowEditComplete}
                            emptyMessage="No record found."
                            // tableStyle={{ minWidth: '50rem' }}
                        >
                            <Column field="point" header="Ride Point" editor={(options) => textEditor(options)} style={{ width: "20%" }}></Column>
                            <Column field="price" header="Price" body={priceBodyTemplate} editor={(options) => priceEditor(options)} style={{ width: "20%" }}></Column>
                            <Column rowEditor={true} headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                        </DataTable>
                    </div>
                </form>
            )}
        </>
    );
}
