import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditUserDialog from "../components/edit_passenger_dialog";
//import { getUsersList } from "../../../../../redux/actions/users_actions";
import { useHistory } from "react-router-dom";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Avatar } from "primereact/avatar";
import UserProfileDialog from "../components/user_profile_dialog";
import { InputText } from "primereact/inputtext";
import CustomPageHeader from "../../../components/custom_page_header";
import { getUsersList } from "../../../../../redux/slices/users_slice";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import ProfileEditDialog from "../components/profile_edit_dialog";
import { Dropdown } from "primereact/dropdown";
import male from "../../../../../assets/img/male.png";
import female from "../../../../../assets/img/female.png";
import { useFormik } from "formik";
import { axiosApi } from "../../../constants/axios_api";
import owner from "../../../../../assets/img/owner.png";
import passenger from "../../../../../assets/img/passenger.png";
import { Tooltip } from "primereact/tooltip";

export default function UsersScreen(props) {
    const permissions = props?.permissions;
    //redux
    const getUsersListReducer = useSelector((state) => state.usersList);
    const { users, loading, count } = getUsersListReducer;
    const [showProfileDialog, setProfileDialog] = useState(false);
    const [first, setFirst] = useState(0);
    const [defaultImage, setDefaultImage] = useState("app/black-watch.jpg");
    const [pageNumber, setPageNumber] = useState(0);
    const [rows, setRow] = useState(null);
    //hooks
    const dispatch = useDispatch();
    const [editUser, setEditUser] = useState(null);
    const [editDialog, setEditDialog] = useState(false);
    const [profileEditDialog, setprofileEditDialog] = useState(false);
    const history = useHistory();
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [selectedGender, setSelectedGender] = useState("");
    const [selectedLoginDevice, setSelectedLoginDevice] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [corporateList, setCorporateList] = useState();
    const [allCorporateOptions, setAllCorporateOptions] = useState([]);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };

    //dialogs
    const dialogFuncMap = {
        editDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
        profileEditDialog: setprofileEditDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const formik = useFormik({
        initialValues: {
            corporate: "",
        },
    });
    //use effects
    let corporate = formik.values.corporate;
    useEffect(() => {
        if (globalFilterValue) {
            setPageNumber(0);
            setFirst(0);
        }
        dispatch(
            getUsersList({
                pageNumber,
                text: globalFilterValue,
                perPageRecords: rows || 50, // Default to 50 if rows is null or undefined
                gender: selectedGender,
                loginDevice: selectedLoginDevice,
                status: selectedStatus,
                corporate: corporate,
            })
        );
    }, [globalFilterValue, selectedGender, selectedLoginDevice, selectedStatus, corporate, pageNumber, rows, dispatch]);
    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        dispatch(
            getUsersList({
                pageNumber: event.first / event.rows,
                text: globalFilterValue,
                perPageRecords: event.rows || 50, // Default to 50 if event.rows is null or undefined
                gender: selectedGender,
                loginDevice: selectedLoginDevice,
                status: selectedStatus,
                corporate: corporate,
            })
        );
    };
    //table body
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Button
                        tooltip="View Ratings"
                        onClick={() => {
                            history.push(`/api/user/passengers/ratings/list/${rowData?._id}/2`);
                        }}
                        icon="pi pi-star-fill"
                        tooltipOptions={{ position: "top" }}
                        className="p-button-warning m-2 p-button-rounded"
                    />
                )}
                {permissions?.edit && (
                    <Button
                        tooltip="Edit Status"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("editDialog");
                        }}
                        className="edit m-2 p-button-rounded"
                    />
                )}

                {permissions?.view && (
                    <Button
                        tooltip="Rides"
                        icon="pi pi-car"
                        tooltipOptions={{ position: "top" }}
                        className="p-button-success m-2 p-button-rounded"
                        onClick={() => {
                            history.push(`/api/user/passengers/rides/list/${rowData?._id}/2`);
                        }}
                    />
                )}
                {permissions?.view && (
                    <Avatar
                        icon="pi pi-user"
                        className="m-2 p-button-rounded"
                        size="large"
                        shape="circle"
                        tooltipOptions={{ position: "top" }}
                        tooltip="View Profile"
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("showProfileDialog");
                        }}
                    />
                )}
                {/* {permissions?.view && (
                    <Avatar
                        icon="pi pi-user-edit"
                        className="m-2 p-button-rounded"
                        size="large"
                        shape="circle"
                        tooltipOptions={{ position: "top" }}
                        tooltip="Edit Profile"
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("profileEditDialog");
                        }}
                    />
                )} */}
            </div>
        );
    };
    // const statusBody = (rowData) => {
    //     return <div className={rowData.active.status ? "bg_green" : "bg_red"}>{rowData.active.status ? "Active" : "Inactive"}</div>;
    // };

    const rowClass = (data) => {
        if (data?.active?.status === true) {
            return {
                "bg-success": true, // Green background for active
            };
        } else if (data?.active?.status === false) {
            return {
                "bg-danger": true, // Red background for inactive
            };
        } else {
            return {}; // No class if no status
        }
    };

    // const rowClass = (data) => {
    //     console.log("data", data);
    //     return {
    //         "bg-primary": data?.active.status == true,
    //     };
    // };

    const userTypeTemp = (rowData) => {
        if (rowData.userType === "3") {
            return (
                <div className="flex">
                    <img src={passenger} title="Passenger" style={{ height: "40px", width: "40px" }} />
                    <img src={owner} title="Owner" style={{ height: "30px", width: "40px", marginTop: "5px" }} />
                </div>
            );
        } else if (rowData.userType === "2") {
            return (
                <div className="">
                    <img src={passenger} title="Passenger" style={{ height: "50px", width: "50px" }} />
                </div>
            );
        } else if (rowData.userType === "1") {
            return (
                <div className="">
                    <img src={owner} title="Owner" style={{ height: "40px", width: "50px" }} />
                </div>
            );
        } else {
            return "";
        }
    };

    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const genderTemp = (rowData) => {
        const gender = rowData.gender;
        return <div>{gender === "female" ? <img src={female} style={{ width: "30px", height: "30px" }} className="ml-3" /> : <img src={male} style={{ width: "30px", height: "30px" }} className="ml-3" />}</div>;
    };
    const signupAsTemp = (rowData) => {
        if (rowData.signupAs == 1) {
            return <img src={owner} title="Owner" style={{ height: "40px", width: "50px" }} />;
        } else if (rowData.signupAs == 2) {
            return <img src={passenger} title="Passenger" style={{ height: "50px", width: "50px" }} />;
        } else {
            return "";
        }
    };
    // const createdAtTemplate = (rowData) => {
    //     console.log("Date", rowData?.createdAt);
    //     return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    // };

    // const updatedAtTemplate = (rowData) => {
    //     return <>{moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a")}</>;
    // };
    const getallCorporates = async () => {
        const response = await axiosApi.get(`${appUrl.baseUrl}api/corporate/corporateLov`);
        setCorporateList(response?.data?.data);
        setAllCorporateOptions(response?.data?.data);
    };

    // Custom template for Created At column
    const createdAtTemplate = (rowData) => {
        const formattedDate = moment(rowData?.createdAt).format("DD/MM/YYYY"); // Only date format
        const fullDate = moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a"); // Full date with time

        return (
            <span className="date-tooltip-container">
                <span className="date-tooltip">{formattedDate}</span>
                <span className="date-full-tooltip">{fullDate}</span>
            </span>
        );
    };

    // Custom template for Updated At column (Similar to Created At)
    const updatedAtTemplate = (rowData) => {
        const formattedDate = moment(rowData?.updatedAt).format("DD/MM/YYYY"); // Only date format
        const fullDate = moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a"); // Full date with time

        return (
            <span className="date-tooltip-container">
                <span className="date-tooltip">{formattedDate}</span>
                <span className="date-full-tooltip">{fullDate}</span>
            </span>
        );
    };

    // Custom header for Created At column
    const dateHeaderTemplate = () => (
        <>
            Created At
            <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>DD/MM/YYYY</div>
        </>
    );

    // Custom header for Updated At column
    const updatedDateHeaderTemplate = () => (
        <>
            Updated At
            <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>DD/MM/YYYY</div>
        </>
    );

    useEffect(() => {
        getallCorporates();
    }, []);
    const clearFilters = () => {
        // Clear formik values
        formik.setFieldValue("corporate", "");
        setGlobalFilterValue("");
        setSelectedGender("");
        setSelectedLoginDevice("");
        setSelectedStatus("");
        setCorporateList(allCorporateOptions);
        setPageNumber(0);
        setFirst(0);
        dispatch(
            getUsersList({
                pageNumber: 0,
                text: "",
                perPageRecords: rows || "",
                gender: "",
                loginDevice: "",
                status: "",
                corporate: "",
            })
        );
    };

    return (
        <>
            <Dialog draggable={false} resizable={false} header="Update Status" visible={editDialog} onHide={() => onHide("editDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "30vw" }}>
                <EditUserDialog permissions={permissions} editUser={editUser} getUsersList={getUsersList} onHide={() => onHide("editDialog")} />
            </Dialog>

            <Dialog draggable={false} resizable={false} header="Profile" position="center" className="text-center" visible={showProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showProfileDialog")}>
                <div>{editUser != null && <UserProfileDialog permissions={permissions} userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog>

            <Dialog draggable={false} resizable={false} header="Edit Profile" position="center" className="text-center" visible={profileEditDialog} style={{ width: "30vw", height: "" }} onHide={() => onHide("profileEditDialog")}>
                <div>{editUser != null && <ProfileEditDialog permissions={permissions} userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog>

            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-7">
                        <CustomPageHeader title={"Passengers"} />
                    </div>
                    <div className="col-12 md:col-11">
                        <div className="mb-9 text-right">
                            <Button style={{ verticalAlign: "top", fontSize: "14px" }} label="Clear Filters" icon="pi pi-times" className=" mr-1 text-left" onClick={clearFilters} />
                            <Dropdown style={{ width: "140px" }} className="mr-1 text-left" options={corporateList} optionLabel="name" optionValue="_id" id="corporate" name="corporate" value={formik.values.corporate || ""} onChange={formik.handleChange} placeholder="Corporate" />
                            <Dropdown
                                style={{ width: "140px" }}
                                className=""
                                value={selectedGender}
                                options={[
                                    {
                                        text: "Male",
                                        value: "male",
                                    },
                                    {
                                        text: "Female",
                                        value: "female",
                                    },
                                ]}
                                optionLabel="text"
                                optionValue="value"
                                onChange={(e) => {
                                    setSelectedGender(e.value);
                                }}
                                placeholder="Gender"
                            />
                            <Dropdown
                                style={{ width: "110px" }}
                                className="ml-1 "
                                value={selectedStatus}
                                options={[
                                    {
                                        text: "Active",
                                        value: 1,
                                    },
                                    {
                                        text: "Inactive",
                                        value: 0,
                                    },
                                ]}
                                optionLabel="text"
                                optionValue="value"
                                onChange={(e) => {
                                    setSelectedStatus(e.value);
                                }}
                                placeholder="Status"
                            />
                            <Dropdown
                                style={{ width: "140px" }}
                                className="mx-1"
                                value={selectedLoginDevice}
                                options={[
                                    {
                                        text: "IOS",
                                        value: "ios",
                                    },
                                    {
                                        text: "Android",
                                        value: "android",
                                    },
                                ]}
                                optionLabel="text"
                                optionValue="value"
                                onChange={(e) => {
                                    setSelectedLoginDevice(e.value);
                                }}
                                placeholder="Login Device"
                            />

                            <span className="p-input-icon-right ">
                                <InputText style={{ width: "140px" }} className="mb-3" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                        </div>
                    </div>
                </div>
                <DataTable loading={loading} responsiveLayout="scroll" rowClassName={rowClass} value={users} key="_id" globalFilterFields={["firstName", "lastName", "cnic", "mobile", "gender", "type"]} emptyMessage="No record found.">
                    <Column
                        className="maker_colm"
                        header="Image"
                        body={(data) => {
                            return (
                                <div>
                                    <Image imageStyle={{ borderRadius: "50%", width: "30px", height: "30px" }} src={data.profileImage ? `${appUrl.fileBaseUrl}${data.profileImage}` : defaultImage} onError={() => setDefaultImage("app/black-watch.jpg")} />
                                </div>
                            );
                        }}
                    />
                    <Column className="maker_colm" style={{ maxWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} body={firstNameTemp} header="First Name" />
                    <Column className="maker_colm" style={{ maxWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} body={lastNameTemp} header="Last Name" />
                    <Column className="maker_colm" style={{ minWidth: "" }} field="cnic" header="CNIC" />
                    <Column style={{ minWidth: "" }} bodyClassName="text-center" className="Action_col" body={userTypeTemp} header="Type" />
                    <Column className="maker_colm" style={{ minWidth: "" }} field="mobile" header="Mobile" />
                    <Column className="maker_colm" style={{ maxWidth: "5rem" }} bodyClassName="text-center" body={genderTemp} header="Gender" />
                    <Column className="maker_colm" style={{ minWidth: "" }} bodyClassName="text-center" body={signupAsTemp} header="Initial Signup" />
                    <Column
                        bodyClassName="text-center"
                        style={{ minWidth: "" }}
                        header="Current Login"
                        body={(rowData) => {
                            if (rowData.loginDevice == "android") return <img height="25" width="25" src="images/android.png" title={rowData.loginDevice === "android" ? "Android" : "IOS"} />;
                            else if (rowData.loginDevice == "ios") return <img height="25" width="25" src="images/apple.png" title={rowData.loginDevice === "android" ? "Android" : "IOS"} />;
                            else return rowData.loginDevice;
                        }}
                    />
                    <Column
                        bodyClassName="text-center"
                        style={{ minWidth: "" }}
                        header="Signup Device"
                        body={(rowData) => {
                            if (rowData.signupDevice == "android") return <img height="25" width="25" src="images/android.png" title={rowData.signupDevice === "android" ? "Android" : "IOS"} />;
                            else if (rowData.signupDevice == "ios") return <img height="25" width="25" src="images/apple.png" title={rowData.signupDevice === "android" ? "Android" : "IOS"} />;
                            else return rowData.signupDevice;
                        }}
                    />
                    {/* <Column style={{ minWidth: "8rem" }} body={createdAtTemplate} header="Created At" /> */}
                    {/* <Column style={{ minWidth: "8rem" }} body={updatedAtTemplate} header="Updated At" /> */}
                    <Column className="maker_colm" style={{ minWidth: "8rem" }} body={createdAtTemplate} header={dateHeaderTemplate} />
                    <Column className="maker_colm" style={{ minWidth: "8rem" }} body={updatedAtTemplate} header={updatedDateHeaderTemplate} />
                    {/* <Column body={statusBody} header="Status" /> */}
                    <Column header="Action" className="Action_col" body={actionTemplate} />
                </DataTable>
                <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
            </div>
        </>
    );
}
