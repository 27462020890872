import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomLoading from '../../../components/custom_loading';
import { useEffect } from 'react';
import { getUserById } from '../../../../../redux/slices/users_slice';
import { useState } from 'react';

function ProfileEditDialog({ userId, editUserData, permissions }) {
  //redux
  const { usersLoading: loading, user } = useSelector((state) => state.usersList);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserById({ userId, isDriver: true }));
    return () => { };
  }, []);
  return (
    <>
      {loading === true || user === undefined ? (
        <CustomLoading />
      ) : (
        <section className=' '>
          {/* <div className="grid "> */}
            <div className="col-12 ">
              <div className="p-fluid grid">
                <div className="col-12 md:col-12">
                  <div className="field">
                    <label className='flex align-items-start'><span className='font-bold'>Username</span></label>
                    <InputText id="username" readOnly value={user?.firstName + '  ' + user?.lastName} aria-describedby="username-help"/>
                  </div>
                </div>
                <div className="col-12 md:col-12">
                  <div className="field">
                  <label className='flex align-items-start'><span className='font-bold'>Mobile Number</span></label>
                  <InputText id="mobile" readOnly value={user?.mobile} aria-describedby="username-help" />
                    </div>
                </div>
                <div className="col-12 md:col-12">
                  <div className="field">
                  <label className='flex align-items-start'><span className='font-bold'>CNIC</span></label>
                  <InputText id="cnic" readOnly value={user?.cnic} aria-describedby="username-help" />
                    </div>
                </div>
                {/* <Button label='Submit' /> */}
              </div>
            {/* </div> */}
          </div>
        </section>
      )}
    </>
  )
}

export default ProfileEditDialog
