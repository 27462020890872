import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import AddEditTutorial from "../pages/add-edit-tutorial-dailoge";
import { deleteTutorial } from "../../../../../redux/slices/tutorials_slice";

// Inside your TutorialItem component

const TutorialItem = ({ tutorial, video, title }) => {
    const toast = useRef(null);
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(false);
    const [editTutorial, setEditTutorial] = useState(null);
    const [showAddEditTutorialDialog, setShowAddEditTutorialDialog] = useState(false);

    const accept = () => {
        dispatch(deleteTutorial(tutorial._id));
    };

    const deleteTutorialsCategory = () => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
        });
    };

    const extractVideoId = (url) => {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    // Extract video ID
    const videoId = extractVideoId(video);
    const videoSrc = videoId ? `https://www.youtube.com/embed/${videoId}` : "";

    return (
        <>
            <div>
                <Dialog draggable={false} resizable={false} header="Update Tutorial" visible={showAddEditTutorialDialog} onHide={() => setShowAddEditTutorialDialog(false)} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                    <AddEditTutorial editData={editTutorial} tutorial={tutorial} onHide={() => setShowAddEditTutorialDialog(false)} />
                </Dialog>
                <div
                    className="card"
                    style={{ background: "lightGrey" }}
                    onClick={() => setExpanded(!expanded)}
                >
                    <ConfirmDialog />
                    <Toast ref={toast} />
                    <div className="grid">
                        <h4>{title}</h4>
                        <div className="col-10"></div>
                        {videoId && (
                            <iframe
                                width="560"
                                height="315"
                                src={videoSrc}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        )}
                        <div className="col-2">
                            <Button
                                icon="pi pi-pencil"
                                onClick={() => {
                                    setShowAddEditTutorialDialog(true);
                                    setEditTutorial(tutorial);
                                }}
                                className="p-button-rounded p-button-text p-0"
                                aria-label="Edit"
                                style={{ color: "blue", cursor: "pointer" }}
                            />
                            <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-text"
                                aria-label="Delete"
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={deleteTutorialsCategory}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TutorialItem;
