import React from "react";
import { Link } from "react-router-dom";
import appUrl from "../../../constants/appUrl";

export default function TicketComponent({ ticket, status }) {
    const formattedDate = ticket.chat.length === 0 ? new Date("2023-01-04T09:35:50.347Z").toLocaleString() : new Date(ticket.chat[0].createdAt).toLocaleString();
    // const formattedDate =
    //     ticket?.chat?.length === 0
    //         ? new Date().toLocaleString() // Default to current date if no chat
    //         : new Date(ticket.chat[0]?.createdAt).toLocaleString(); // Else use actual created date

    const chatText = ticket.chat.length > 0 ? ticket.chat[0].text : "";
    const truncatedChatText = chatText.length > 20 ? chatText.substring(0, 20) + "..." : chatText;

    return (
        <>
            <div className="col-12 lg:col-12 xl:col-12">
                <div className="card mb-1 ">
                    <Link to={`/api/support/${status}/chat/${ticket._id}`}>
                        <div></div>
                        <div className="flex justify-content-between">
                            <span>
                                <span>
                                    {" "}
                                    <img className="round-image" style={{ width: "50px", height: "50px" }} src={appUrl.baseUrl + ticket?.userId?.profileImage} />
                                </span>
                                <span style={{ marginLeft: "10px", position: "relative", top: "-25px" }}>{`${ticket?.userId?.firstName} ${ticket?.userId?.lastName}`}</span>
                                <br /> <span style={{ marginLeft: "50px", position: "relative", top: "-20px" }}>{truncatedChatText}</span>
                            </span>

                            <span className="block text-500 font-medium mb-3">
                                <span className="mr-3">Subject:</span>
                                {ticket.subject}
                            </span>
                            <strong className="">
                                <p>{String(formattedDate)}</p>
                            </strong>
                        </div>
                        <div></div>
                    </Link>
                </div>
            </div>
        </>
    );
}
