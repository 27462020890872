import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Rating } from "primereact/rating";
import CustomLoading from "../../../components/custom_loading";
import { Dialog } from "primereact/dialog";
import AddEditNotificationDialog from "../../drivers/components/notification_driver_dialog";
import { getUserById } from "../../../../../redux/slices/users_slice";
const UserAccountProfileDialog = ({ userId, permissions }) => {
    const [editUser] = useState(null);
    const [showNotificationDialog, setNotificationDialog] = useState(false);
    //redux
    const { usersLoading: loading, usersError: error, user } = useSelector((state) => state.usersList);
    //hooks
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserById({ userId, isDriver: true }));
        return () => {};
    }, []);

    const dialogFuncMap = {
        showNotificationDialog: setNotificationDialog,
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const getRating = (totalRating, totalRatingCount) => {
        if (totalRating === 0) {
            return 0;
        }
        return totalRating / totalRatingCount;
    };

    return (
        <>
            {loading === true || user === undefined ? (
                <CustomLoading />
            ) : (
                <div>
                    <Dialog draggable={false} resizable={false} header="Notification" visible={showNotificationDialog} onHide={() => onHide("showNotificationDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                        <AddEditNotificationDialog permissions={permissions} editUser={editUser} onHide={() => onHide("showNotificationDialog")} />
                    </Dialog>
                    <div className="grid">
                        <div className="col-12 md:col-4">
                            <Image width="100%" height="300px" preview style={{ objectFit: "cover" }} template="View" src={`${appUrl.fileBaseUrl}${user?.profileImage}`} />
                        </div>
                        <div className="col-12 md:col-8 text-left">
                            <div className="justify-content-center" style={{ display: "flex", alignItems: "center" }}>
                                <div className="font-bold text-4xl  p-fluid">{`${user?.firstName} ${user?.lastName}`}</div>
                            </div>
                            <div className="justify-content-center text-center">
                                <Rating className="pt-3 " value={getRating(user.totalRating, user.totalRatingCount)} cancel={false} stars={5} />
                            </div>

                            <div className="grid">
                                <div className="col-12 md:col-6  p-fluid">
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary wdth_50">Mobile</span> : <span className="">{user.mobile}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">CNIC</span> : <span className="">{user.cnic}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">Gender</span> : <span className="">{user.gender}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">Email</span> : <span className="">{user.email}</span>
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 ml__5  p-fluid">
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary wdth_50">Type</span> : <span className="">{user.userType === "1" ? "Owner" : user.userType === "2" ? "Passenger" : "Both owner and passenger"}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">Date of birth</span> : <span className="">{user.dob}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">Invite code</span> : <span className="">{user.inviteCode}</span>
                                        <div className="flex flex-row ">
                                            <div className="flex"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserAccountProfileDialog;
