import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import CustomLoading from "../../../components/custom_loading";
import { Dialog } from "primereact/dialog";
import AddEditNotificationDialog from "../../drivers/components/notification_driver_dialog";
import { getUserById } from "../../../../../redux/slices/users_slice";
import ResetPassword from "./reset_password_dailog";
const UserProfileDialog = ({ userId, editUserData, permissions }) => {
    const [editUser, setEditUser] = useState(null);
    const [showNotificationDialog, setNotificationDialog] = useState(false);
    const [showResetPasswordDialog, setResetPasswordDialog] = useState(false);
    //redux
    const { usersLoading: loading, user } = useSelector((state) => state.usersList);
    //hooks
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserById({ userId, isDriver: true }));
        // //console.log(userId)
        return () => {};
    }, []);

    // //console.log("userId", userId)
    // //console.log("user edit", editUserData)
    const dialogFuncMap = {
        showNotificationDialog: setNotificationDialog,
        showResetPasswordDialog: setResetPasswordDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const getRating = (totalRating, totalRatingCount) => {
        if (totalRating === 0) {
            return 0;
        }
        return totalRating / totalRatingCount;
    };
    return (
        <>
            {loading === true || user === undefined ? (
                <CustomLoading />
            ) : (
                <div>
                    <Dialog draggable={false} resizable={false} header="Notification" visible={showNotificationDialog} onHide={() => onHide("showNotificationDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                        <AddEditNotificationDialog permissions={permissions} editUser={editUser} onHide={() => onHide("showNotificationDialog")} />
                    </Dialog>
                    <Dialog draggable={false} resizable={false} header="Reset Password" visible={showResetPasswordDialog} onHide={() => onHide("showResetPasswordDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                        <ResetPassword permissions={permissions} editUserData={editUserData} editUser={editUser} onHide={() => onHide("showResetPasswordDialog")} mobile={user.mobile} />
                    </Dialog>
                    <div className="grid">
                        <div className="col-12 md:col-4">
                            <Image width="100%" height="300px" preview style={{ objectFit: "cover" }} template="View" src={`${appUrl.fileBaseUrl}${user?.profileImage}`} />
                        </div>
                        <div className="col-12 md:col-8 text-left">
                            <div className="justify-content-center" style={{ display: "flex", alignItems: "center" }}>
                                <div className="font-bold text-4xl  p-fluid">{`${user?.firstName} ${user?.lastName}`}</div>
                                {/* <div>{notificationBtn()}</div> */}
                            </div>
                            <div className="justify-content-center text-center">
                                <Rating className="pt-3 " value={getRating(user.totalRating, user.totalRatingCount)} cancel={false} stars={5} />
                            </div>

                            <div className="grid">
                                <div className="col-12 md:col-6  p-fluid">
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary wdth_50">Mobile</span> : <span className="">{user.mobile}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">CNIC</span> : <span className="">{user.cnic}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">Gender</span> : <span className="">{user.gender.charAt(0).toUpperCase() + user.gender.slice(1).toLowerCase()}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">Email</span> : <span className="">{user.email}</span>
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 ml__5  p-fluid">
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary wdth_50">Type</span> : <span className="">{user.userType === "1" ? "Owner" : user.userType === "2" ? "Passenger" : "Both owner and passenger"}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">Date of birth</span> : <span className="">{user.dob}</span>
                                    </div>
                                    <div className="text-lg mt-1 pt-3">
                                        <span className="text-primary  wdth_50">Invite code</span> : <span className="">{user.inviteCode}</span>
                                        {/* balance */}
                                        <div className="flex flex-row ">
                                            <div className="flex"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 pt-4 text-center">
                                    <div>
                                        {permissions?.view && (
                                            <Button
                                                label="View routes"
                                                className="p-button-round p-button-success custom___width mr-2"
                                                onClick={() => {
                                                    history.push(`/api/user/drivers/rides/list/${user?._id}/1`);
                                                }}
                                            />
                                        )}
                                        {permissions?.view && (
                                            <Button
                                                label="View schedules"
                                                className=" p-button-secondary p-button-round custom___width mr-2"
                                                onClick={() => {
                                                    history.push(`/api/user/passengers/rides/list/${user?._id}/2`);
                                                }}
                                            />
                                        )}
                                        {permissions?.edit && (
                                            <Button
                                                label="Reset Password"
                                                className=" p-button-secondary p-button-round custom___width"
                                                onClick={() => {
                                                    setEditUser();
                                                    onClick("showResetPasswordDialog");
                                                }}
                                                // onClick={() => {
                                                //     // history.push(`/api/user/passengers/rides/list/${user._id}/2`);
                                                // }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserProfileDialog;
