import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { getPaymentRequestList } from "../../../../../redux/slices/payment_request_slice";
import * as XLSX from "xlsx";
import { FileUpload } from "primereact/fileupload";
import axios from "axios";
import appUrl from "../../../constants/appUrl";
import { toast } from "react-toastify";
import decryptData from "../../../services/decryptData";
import { Dropdown } from "primereact/dropdown";
import CustomPageHeader from "../../../components/custom_page_header";
import { Paginator } from "primereact/paginator";

const statusList = [
    { status: "Pending", value: "pending" },
    { status: "Paid", value: "paid" },
];

const mobileBodyTemplate = (rowData) => {
    return `+${rowData.userId.mobile}`;
};

export default function PaymentRequest({ permissions }) {
    const { data: list, count } = useSelector((state) => state.paymentRequest);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [selectedPaymentsRows, setSelectedPaymentsRows] = useState(null);
    const [rows, setRows] = useState(50);
    const [first, setFirst] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [status, setStatus] = useState("pending");
    const dispatch = useDispatch();
    const fileUploadRef = useRef(null);

    useEffect(() => {
        dispatch(getPaymentRequestList({ page: 1, status, limit: rows, searchText: globalFilterValue }));
    }, [status]);

    useEffect(() => {
        dispatch(getPaymentRequestList({ page: pageNumber, status, limit: rows, searchText: globalFilterValue }));
    }, [globalFilterValue]);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
        dispatch(getPaymentRequestList({ page: pageNumber, status, limit: rows, searchText: value }));
    };

    const onStatusChange = (e) => {
        setStatus(e.value);
        setSelectedPaymentsRows(null); // Reset selected rows
        dispatch(getPaymentRequestList({ page: 1, status: e.value, limit: rows, searchText: globalFilterValue }));
    };

    const exportSelectedRowsToExcel = () => {
        if (!selectedPaymentsRows || selectedPaymentsRows.length === 0) {
            toast.info("Please select rows to export.");
            return;
        }

        const excelData = selectedPaymentsRows.map((row) => ({
            "First Name": row?.userId?.firstName || "",
            "Last Name": row?.userId?.lastName || "",
            Mobile: row?.userId?.mobile || "",
            Email: row?.userId?.email || "",
            Amount: row?.amount || "",
            "Transaction Id": row?.transactionId || "",
            UserId: row?.userId?._id || "",
            Status: row?.status || "",
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Payment Requests");
        XLSX.writeFile(workbook, "selected_payment_requests.xlsx");
    };

    const onUpload = async (event) => {
        const uploadedFile = event.files[0];
        const formData = new FormData();
        formData.append("file", uploadedFile);
        try {
            const response = await axios.post(appUrl.baseUrl + appUrl.uploadPaymentRequest, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            let decryptedData = decryptData(response?.data?.cipher);
            if (response.status === 200) {
                toast.success(decryptedData?.msg);
                fileUploadRef.current.clear(); // Reset the FileUpload component
                dispatch(getPaymentRequestList({ page: pageNumber, status, limit: rows, searchText: globalFilterValue })); // Refresh the list
            }
        } catch (error) {
            let decryptedData = decryptData(error?.response?.data?.cipher);
            toast.error(decryptedData.msg);
        }
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        setRows(event.rows);
        dispatch(getPaymentRequestList({ page: event.first / event.rows, status, limit: event.rows, searchText: globalFilterValue }));
    };

    return (
        <div className="card">
            <div className="col-12 flex justify-content-between flex-wrap mb-3">
                <div className="col-3">
                    <CustomPageHeader title={"Payment Request"} />
                </div>
                <div className="mr-4 col-8 text-right flex flex-wrap">
                    <div className="p-grid">
                        <div className="p-col">
                            <Dropdown className="ml-3" value={status} options={statusList} optionLabel="status" optionValue="value" onChange={onStatusChange} placeholder="Select a Status" />
                        </div>
                    </div>
                    <span className="p-input-icon-right mx-3">
                        <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                    </span>
                    <Button style={{ fontSize: "14px" }} className="p-button-success ml-2 p-button-rounded p-button-sm" label="Export to Excel" iconPos="left" icon="pi pi-download" onClick={exportSelectedRowsToExcel} />
                    <FileUpload 
                        ref={fileUploadRef}
                        className="p-button-success ml-2 p-button-rounded p-button-sm file-upload-custom" 
                        name="file" 
                        customUpload 
                        uploadHandler={onUpload} 
                        accept=".xlsx,.xls" 
                        maxFileSize={1000000} 
                        chooseLabel="Upload Excel File" 
                        mode="basic" 
                        icon="pi pi-upload" 
                    />
                </div>
            </div>
            <DataTable filter rows={50} value={list} responsiveLayout="scroll" emptyMessage="No record found." selectionMode="checkbox" selection={selectedPaymentsRows} onSelectionChange={(e) => setSelectedPaymentsRows(e.value)} dataKey="_id" stripedRows>
                <Column selectionMode="multiple" style={{ width: "3em", textAlign: "center", verticalAlign: "middle" }} />
                <Column field="transactionId" header="Transaction Id" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "start", paddingLeft: "14px" }} />
                <Column field="userId.firstName" header="First Name" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "start", paddingLeft: "14px" }} />
                <Column field="userId.lastName" header="Last Name" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "start", paddingLeft: "14px" }} />
                <Column field="userId.mobile" header="Mobile" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "start", paddingLeft: "14px" }} body={mobileBodyTemplate} />
                <Column field="userId.email" header="Email" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "start", paddingLeft: "14px" }} />
                <Column field="paymentGatewayId.name" header="Name" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "start", paddingLeft: "14px" }} />
                <Column field="amount" header="Total Amount(PKR)" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "start", paddingLeft: "14px" }} />
            </DataTable>

            <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
        </div>
    );
}
