import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import CustomLoading from "../../../components/custom_loading";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { getreferrrerList } from "../../../../../redux/slices/referrer_slice";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import UserAccountProfileDialog from "../../accounts/components/user_account_profile";
import { InputText } from "primereact/inputtext";

export default function ReferrerScreen({ permissions }) {
    const [search, setSearch] = useState("");
    const [isTableLoading, setIsTableLoading] = useState(false); // New loading state for DataTable
    const [pageLoading, setPageLoading] = useState(true); // New state for page loading
    const referrerReducer = useSelector((state) => state.referrer);
    const { data: list, loading } = referrerReducer;
    const [showReferrerProfileDialog, setReferrerProfileDialog] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const dispatch = useDispatch();
    const [editReferrerUser, setEditReferrerUser] = useState(null);
    const [defaultImage] = useState("app/black-watch.jpg");

    console.log("referrerReducer", referrerReducer);

    // const onGlobalFilterChange = (e) => {
    //     const value = e.target.value;
    //     setSearch(value); // Update the search value
    // };

    // Handle search input
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setSearch(value); // Update search state
        setIsTableLoading(true); // Show DataTable-specific loading

        // Fetch data with search value
        dispatch(getreferrrerList({ search: value })).finally(() => {
            setIsTableLoading(false); // Hide loading when data is fetched
        });
    };

    //dialogs
    const dialogFuncMap = {
        showReferrerProfileDialog: setReferrerProfileDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const signupAsTemp = (rowData) => {
        if (rowData?.signupAs == 1) {
            return "Owner";
        } else if (rowData?.signupAs == 2) {
            return "Passenger";
        } else {
            return "";
        }
    };
    const currentRoleTemp = (rowData) => {
        if (rowData.currentRole == 1) {
            return "Owner";
        } else if (rowData.currentRole == 2) {
            return "Passenger";
        } else {
            return "Owner, Passenger";
        }
    };
    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };
    const referrerUserTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Avatar
                        icon="pi pi-user"
                        className="m-2 p-button-rounded"
                        size="large"
                        shape="circle"
                        tooltipOptions={{ position: "top" }}
                        tooltip="View Profile"
                        onClick={() => {
                            setEditReferrerUser(rowData);
                            onClick("showReferrerProfileDialog");
                        }}
                    />
                )}
            </div>
        );
    };
    const rowExpansionTemplate = (rowData) => {
        // Ensure rowData.users is a valid array
        const users = Array.isArray(rowData.users) ? rowData.users : [];

        return (
            <>
                <h5>Users</h5>
                {/* Ensure there's data to display */}
                {users.length > 0 ? (
                    <DataTable showGridlines={true} value={users}>
                        <Column
                            className="maker_colm"
                            header="Image"
                            body={(data) => {
                                return (
                                    <div>
                                        <Image
                                            imageStyle={{
                                                borderRadius: "50%",
                                                width: "50px",
                                                height: "50px",
                                            }}
                                            src={`${appUrl.fileBaseUrl}${data.profileImage}` ? `${appUrl.fileBaseUrl}${data.profileImage}` : defaultImage}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Column className="maker_colm" style={{ padding: "2rem 1rem" }} field="firstName" header="First Name" />
                        <Column className="maker_colm" style={{ padding: "2rem 1rem" }} field="lastName" header="Last Name " />
                        <Column className="maker_colm" style={{ padding: "2rem 1rem" }} body={createdAtTemplate} header="Signup Time " />
                        <Column
                            className="maker_colm"
                            style={{ minWidth: "" }}
                            header="Signup Device"
                            body={(rowData) => {
                                if (rowData.signupDevice === "android") return <img height="25" width="25" src="images/android.png" />;
                                else if (rowData.signupDevice === "ios") return <img height="25" width="25" src="images/apple.png" />;
                                else return rowData.signupDevice;
                            }}
                        />
                        <Column className="maker_colm" body={signupAsTemp} header="Signup As" />
                        <Column className="maker_colm" body={currentRoleTemp} header="Current Role" />
                    </DataTable>
                ) : (
                    <p>No users found.</p> // Show a message if no users
                )}
            </>
        );
    };

    // Ensure that the expansion is allowed only if there are users to display
    const allowExpansion = (rowData) => {
        return Array.isArray(rowData.users) && rowData.users.length > 0;
    };

    useEffect(() => {
        dispatch(getreferrrerList({ search })).finally(() => {
            setPageLoading(false); // Hide page loader after data is fetched
        });
    }, [dispatch]);

    return (
        <>
            {pageLoading ? (
                <CustomLoading />
            ) : (
                <>
                    <Dialog draggable={false} resizable={false} header="Profile" position="center" className="text-center" visible={showReferrerProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showReferrerProfileDialog")}>
                        <div>{editReferrerUser != null && <UserAccountProfileDialog permissions={permissions} userId={editReferrerUser.referBy.referById} user={editReferrerUser} userType="1" />}</div>
                    </Dialog>

                    <div className="card">
                        <div className="flex align-items-center justify-content-between pb-3">
                            <div>
                                <h4>Referrer</h4>
                            </div>
                            <div>
                                <span className="p-input-icon-right ml-3">
                                    <InputText className="mbl_view" value={search} onChange={onGlobalFilterChange} placeholder="Search" />
                                </span>
                            </div>
                        </div>
                        {isTableLoading ? (
                            <CustomLoading />
                        ) : (
                            <DataTable
                                filter
                                expandedRows={expandedRows}
                                rows={5}
                                paginator
                                loading={loading}
                                value={list}
                                responsiveLayout="scroll"
                                onRowToggle={(e) => {
                                    setExpandedRows(e.data);
                                }}
                                rowExpansionTemplate={rowExpansionTemplate}
                            >
                                <Column className="maker_colm" expander={allowExpansion} style={{ width: "3em" }} />

                                <Column
                                    className="maker_colm"
                                    header="Image"
                                    body={(data) => {
                                        return (
                                            <div style={{ marginLeft: "2rem !important" }}>
                                                <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px", marginLeft: "1rem" }} src={`${appUrl.fileBaseUrl}${data.referBy.profileImage}` ? `${appUrl.fileBaseUrl}${data.referBy.profileImage}` : defaultImage} />
                                            </div>
                                        );
                                    }}
                                />
                                <Column className="maker_colm" style={{ padding: "2rem 1rem" }} field="referBy.firstName" header="First Name" />
                                <Column className="maker_colm" style={{ padding: "2rem 1rem" }} field="referBy.lastName" header="Last Name" />
                                <Column className="maker_colm" style={{ padding: "2rem 1rem" }} field="referBy.inviteCode" header="Invite Code" />
                                <Column className="maker_colm" style={{ padding: "2rem 1rem" }} field="total" header="Total Referrer" />
                                {permissions?.view && <Column className="maker_colm" header="Action" body={referrerUserTemplate} />}
                            </DataTable>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
