import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { deleteAds, getAdsList, resetAddEditDeleteAds } from "../../../../../redux/slices/ads_slice";
import CustomPageHeader from "../../../components/custom_page_header";
import appUrl from "../../../constants/appUrl";
import { Image } from "primereact/image";
import { confirmDialog } from "primereact/confirmdialog";
// import AddEditPaymentDialog from "../componenets/add_edit_ad_dialog";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Paginator } from "primereact/paginator";
import AddEditPaymentDialog from "../components/add_edit_payment_dialog";
import { deletePaymentMethods, getPaymentMethodsList, resetAddEditDeletePayments } from "../../../../../redux/slices/payment_methods_slice";

export default function PaymentMethods({ permissions }) {
    const dispatch = useDispatch();

    const [editData, setEditData] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
        setFilters({
            global: { value, matchMode: FilterMatchMode.CONTAINS },
        });
        dispatch(getPaymentMethodsList({ pageNumber, text: value, perPageRecords: rows }));
    };
    let deleteId;

    const { data: list, loading, deleteSuccess, deleteError, count = 0 } = useSelector((state) => state.paymentMethods);
    const confirmDeleteAd = () => {
        confirmDialog({
            message: "Do you want to delete this ad?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-success p-button-rounded",
            accept,
        });
    };
    const accept = () => {
        dispatch(deletePaymentMethods(deleteId));
    };

    useEffect(() => {
        if (list === undefined) {
            dispatch(getPaymentMethodsList());
        }
    }, []);

    useEffect(() => {
        if (deleteSuccess !== undefined) {
            if (deleteSuccess === true) {
                toast.success("Payment deleted");
                dispatch(getPaymentMethodsList());
            } else {
                toast.error(deleteError);
            }
        }
        return () => {
            dispatch(resetAddEditDeletePayments());
        };
    }, [deleteSuccess, deleteError]);

    const [showAddDialog, setAddDialog] = useState(false);

    const dialogFuncMap = {
        showAddDialog: setAddDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.edit && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditData(rowData);
                            onClick("showAddDialog");
                        }}
                        className="edit mr-2 p-button-rounded p-button-success"
                    />
                )}
                {permissions?.delete && (
                    <Button
                        tooltip="Delete"
                        icon="pi pi-trash"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            deleteId = rowData?._id;
                            confirmDeleteAd();
                        }}
                        className="edit mr-2 p-button-danger p-button-rounded"
                    />
                )}
            </div>
        );
    };
    useEffect(() => {
        setPageNumber(0);
        setFirst(0);
        dispatch(getPaymentMethodsList({ pageNumber, text: globalFilterValue, perPageRecords: rows }));
    }, [globalFilterValue]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        dispatch(getPaymentMethodsList({ pageNumber: event.first / event.rows, text: globalFilterValue, perPageRecords: event.rows }));
    };

    const statusTemplate = (rowData) => {
        return (
            <Button className={rowData?.status === true ? "bg_green ml-2" : "bg_red ml-2"} style={{ border: "none", boxShadow: "none" }}>
                {rowData?.status === true ? "Active" : "Inactive"}
            </Button>
        );
    };
    return (
        <>
            <div className="card">
                <Dialog draggable={false} resizable={false} header={editData == null ? "Add Payment Method" : "Update Payment Method"} visible={showAddDialog} onHide={() => onHide("showAddDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                    <AddEditPaymentDialog permissions={permissions}
                        getPaymentMethodsList={getPaymentMethodsList}
                    editCode={editData} onHide={() => onHide("showAddDialog")} />
                </Dialog>
                <div className="grid">
                    <div className="col-12 md:col-5">
                        <CustomPageHeader title={"Payment Methods"} />
                    </div>
                    <div className="col-12 md:col-7">
                        <div className="text-right">
                            <span className="p-input-icon-right mr-3">
                                <InputText className="mbl_mrb" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                            {permissions?.add && (
                                <Button
                                    style={{ fontSize: "14px" }}
                                    onClick={() => {
                                        setEditData(null);
                                        onClick("showAddDialog");
                                    }}
                                    label="Add"
                                    className=" p-button-success p-button-rounded p-button-sm custom___width"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <DataTable loading={loading} responsiveLayout="scroll" value={list} emptyMessage="No record found." filters={filters} globalFilterFields={["name", "status"]}>
                    <Column
                        field="icon"
                        className="maker_colm"
                        header="Icon"
                        body={(data) => {
                            return (
                                <div>
                                    <Image width="50px" preview template="Preview" src={`${appUrl.fileBaseUrl}${data?.icon}`} />
                                </div>
                            );
                        }}
                    />
                    <Column className="maker_colm" field="name" header="Name" />
                    <Column className="maker_colm" body={statusTemplate} field="status" header="Status" />
                    <Column className="maker_colm" body={actionTemplate} header="Action" />
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
            </div>
        </>
    );
}
