import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import appUrl from "../../../constants/appUrl";
import { formateDateTime } from "../../../services/date_service";
import { Paginator } from "primereact/paginator";
import moment from "moment";

export default function AllRidesTableComponent({ rides, loading, userType, count, setPageNumber, flag, permissions }) {
    const history = useHistory();
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-ellipsis-h"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            if (userType === "1") {
                                history.push(`/api/rides/${flag}/driver/` + rowData._id);
                            } else {
                                history.push(`/api/rides/${flag}/passenger/` + rowData._id);
                            }
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
            </div>
        );
    };
    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        setPage(event.first / event.rows);
    };

    // Custom header for Date column
    const dateHeaderTemplate = () => (
        <>
            Date
            <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>DD/MM/YYYY</div>
        </>
    );

    // Custom template for Date column
    const dateTemplate = (rowData) => {
        const formattedDate = moment(rowData?.date).format("DD/MM/YYYY"); // Only date format
        const fullDate = moment(rowData?.date).format("MMMM Do YYYY, h:mm a"); // Full date with time

        return (
            <span className="date-tooltip-container">
                <span className="date-tooltip">{formattedDate}</span>
                <span className="date-full-tooltip">{fullDate}</span>
            </span>
        );
    };

    const rowClass = (rowData) => {
        if (rowData.status === "started") {
            return "bg-success"; // Green for "started"
        } else if (rowData.status === "completed") {
            return "bg-green"; // Green for "completed"
        } else if (rowData.status === "active") {
            return "bg-danger"; // Blue for "active"
        } else {
            return "bg-danger"; // Red for other statuses
        }
    };

    return (
        <div className="card">
            <DataTable
                loading={loading}
                responsiveLayout="scroll"
                value={rides}
                key="_id"
                emptyMessage="No record found."
                className="p-datatable-gridlines"
                rowClassName={rowClass} // Apply class based on status
            >
                <Column className="maker_colm" body={(data, props) => <div>{page * 50 + props.rowIndex + 1}</div>} header="Sr" />

                <Column
                    className="maker_colm"
                    header="Image"
                    body={(data) => {
                        return (
                            <div>
                                <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={`${appUrl.fileBaseUrl}${data?.user?.profileImage}`} />
                            </div>
                        );
                    }}
                />
                <Column className="maker_colm" field="user.firstName" header="First Name" />
                <Column className="maker_colm" field="user.lastName" header="Last Name" />
                <Column className="maker_colm" field="user.mobile" header="Mobile" />
                <Column className="maker_colm" field="startPoint.placeName" header="Pickup location" />
                <Column className="maker_colm" field="endPoint.placeName" header="Dropoff location" />
                {/* <Column className="maker_colm" body={(data) => <div>{formateDateTime(data.date)}</div>} field="date" header="Date" /> */}
                <Column className="maker_colm" body={dateTemplate} field="date" header={dateHeaderTemplate} />
                {userType === "2" && <Column className="maker_colm" field="bookedSeats" header="Booked seats" />}
                <Column className="maker_colm" field="gender" header="Gender" />
                {/* <Column
                    className="maker_colm"
                
                    field="status"
                    body={(data) => {
                        let status = data.status === "active" ? "Upcoming" : "Active";
                        return <div>{status}</div>;
                    }}
                    header="Status"
                /> */}
                {permissions?.view && <Column className="maker_colm" header="Action" body={actionTemplate} />}
            </DataTable>
            <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
        </div>
    );
}
