import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRideDetails } from "../../../../../redux/actions/rides_actions";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Column } from "primereact/column";
import { loadGoogleMaps, removeGoogleMaps } from "./GoogleMaps";
import { GMap } from "primereact/gmap";
import CustomLoading from "../../../components/custom_loading";
import { Dialog } from "primereact/dialog";
import ReceiptDialog from "../../../components/receipt_dialog";
import { Button } from "primereact/button";

export default function PassengerRideDetailsScreen({ permissions }) {
    const [showReceiptDialog, setReceiptDialog] = useState(false);
    const [receiptId, setReceiptId] = useState("");
    const dialogFuncMap = {
        showReceiptDialog: setReceiptDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const google = window.google;
    var mapRef = useRef(null);

    const toast = useRef(null);
    const infoWindow = useRef(null);
    const [googleMapsReady, setGoogleMapsReady] = useState(false);

    useEffect(() => {
        loadGoogleMaps(() => {
            setGoogleMapsReady(true);
        });

        return () => {
            removeGoogleMaps();
        };
    }, []);

    //redux
    const getRideDetailsReducer = useSelector((state) => state.getRideDetails);
    const { ride, loading } = getRideDetailsReducer;
    const dispatch = useDispatch();
    const params = useParams();
    const { id } = params;
    //hooks
    const [overlays, setOverlays] = useState([]);
    const onOverlayClick = (event) => {
        let isMarker = event.overlay.getTitle !== undefined;

        if (isMarker) {
            let title = event.overlay.getTitle();
            infoWindow.current = infoWindow.current || new google.maps.InfoWindow();
            infoWindow.setContent("<div>" + title + "</div>");
            infoWindow.open(event.map, event.overlay);
            event.map.setCenter(event.overlay.getPosition());
        }
    };

    const handleDragEnd = (event) => {
        toast.current.show({ severity: "info", summary: "Marker Dragged", detail: event.overlay.getTitle() });
    };

    useEffect(() => {
        dispatch(getRideDetails(id, "passenger"));
        return () => {};
    }, []);

    useEffect(() => {
        if (loading !== undefined && loading === false && ride != undefined && googleMapsReady === true) {
            addMarkerOwnerStart(ride?.startPoint?.latitude, ride?.startPoint?.longitude, ride?.startPoint?.placeName);
            addMarkerOwnerEnd(ride?.endPoint?.latitude, ride?.endPoint?.longitude, ride?.endPoint?.placeName, true);
            addPolyline(ride?.polyline?.map((item) => ({ lat: item[0], lng: item[1] })));
            // console.log("passenger rides",ride?.accepted?.polyline)

            addMarkerPassengerStart(ride?.accepted?.startPoint?.latitude, ride?.accepted?.startPoint?.longitude, ride?.accepted?.startPoint?.placeName, true);
            addMarkerPassengerEnd(ride?.accepted?.endPoint?.latitude, ride?.accepted?.endPoint?.longitude, ride?.accepted?.endPoint?.placeName, true);
            addPolyline2(ride?.accepted?.polyline?.map((item) => ({ lat: item[0], lng: item[1] })));

            // // //console.log(ride.request);
            // setPassengers([...ride.request,...ride.myRequests]);
            const bounds = new window.google.maps.LatLngBounds();

            bounds.extend(new window.google.maps.LatLng(ride.bounds_sw.latitude, ride.bounds_sw.longitude));
            bounds.extend(new window.google.maps.LatLng(ride.bounds_ne.latitude, ride.bounds_ne.longitude));
            mapRef.map.fitBounds(bounds);
        }
    }, [ride, loading, googleMapsReady]);

    const onMapClick = () => {
        // //console.log(event);
        // setDialogVisible(true);
        // setSelectedPosition(event.latLng)
    };

    const addPolyline = (list) => {
        let newPolyline = new google.maps.Polyline({ path: list, geodesic: true, strokeColor: "#FF0000", strokeOpacity: 0.5, strokeWeight: 2 });
        setOverlays((prev) => [...prev, newPolyline]);
    };

    const addMarkerOwnerStart = (lat, lng, title, green = false) => {
        // //console.log(lat, lng, title);
        let newMarker = new google.maps.Marker({
            position: {
                lat: lat,
                lng: lng,
            },
            title: title,
            draggable: false,
            icon: !green
                ? null
                : {
                      path: "m 12,2.4000002 c -2.7802903,0 -5.9650002,1.5099999 -5.9650002,5.8299998 0,1.74375 1.1549213,3.264465 2.3551945,4.025812 1.2002732,0.761348 2.4458987,0.763328 2.6273057,2.474813 L 12,24 12.9825,14.68 c 0.179732,-1.704939 1.425357,-1.665423 2.626049,-2.424188 C 16.809241,11.497047 17.965,9.94 17.965,8.23 17.965,3.9100001 14.78029,2.4000002 12,2.4000002 Z",
                      fillColor: "#00FF00",
                      fillOpacity: 1.0,
                      strokeColor: "#000000",
                      strokeWeight: 1.5,
                      scale: 2,
                      anchor: new google.maps.Point(12, 24),
                  },
        });

        setOverlays((prev) => [...prev, newMarker]);
        // Create an info window
        const infoWindow = new window.google.maps.InfoWindow({
            content: `
            <div>
            <p><b>Owner</b></p>
            <p><b>Name: </b>${ride?.userId?.firstName.charAt(0).toUpperCase() + ride?.userId?.firstName.slice(1)} ${ride?.userId?.lastName.charAt(0).toUpperCase() + ride?.userId?.lastName.slice(1)}</p>
            <p><b>Start Location: </b>${title}</p>
            </div>
            `,
        });

        const func = () => {
            return <div></div>;
        };

        // Open the info window on marker hover
        newMarker.addListener("mouseover", () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener("mouseout", () => {
            infoWindow.close();
        });
    };

    const addMarkerOwnerEnd = (lat, lng, title, green = false) => {
        // //console.log(lat, lng, title);
        let newMarker = new google.maps.Marker({
            position: {
                lat: lat,
                lng: lng,
            },
            title: title,
            draggable: false,
            icon: !green
                ? null
                : {
                      path: "m 12,2.4000002 c -2.7802903,0 -5.9650002,1.5099999 -5.9650002,5.8299998 0,1.74375 1.1549213,3.264465 2.3551945,4.025812 1.2002732,0.761348 2.4458987,0.763328 2.6273057,2.474813 L 12,24 12.9825,14.68 c 0.179732,-1.704939 1.425357,-1.665423 2.626049,-2.424188 C 16.809241,11.497047 17.965,9.94 17.965,8.23 17.965,3.9100001 14.78029,2.4000002 12,2.4000002 Z",
                      fillColor: "#FF0000",
                      fillOpacity: 1.0,
                      strokeColor: "#000000",
                      strokeWeight: 1.5,
                      scale: 2,
                      anchor: new google.maps.Point(12, 24),
                  },
        });

        setOverlays((prev) => [...prev, newMarker]);
        // Create an info window
        const infoWindow = new window.google.maps.InfoWindow({
            content: `
            <div>
                <p><b>Owner</b></p>
                <p><b>Name: </b>${ride?.userId?.firstName.charAt(0).toUpperCase() + ride?.userId?.firstName.slice(1)} ${ride?.userId?.lastName.charAt(0).toUpperCase() + ride?.userId?.lastName.slice(1)}</p>
                <p><b>End Location: </b>${title}</p>
            </div>
   `,
        });

        const func = () => {
            return <div></div>;
        };

        // Open the info window on marker hover
        newMarker.addListener("mouseover", () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener("mouseout", () => {
            infoWindow.close();
        });
    };

    const onMapReady = (event) => {
        setOverlays([
            // new google.maps.Marker({position: {lat: 36.879466, lng: 30.667648}, title:"Konyaalti"}),
            // new google.maps.Marker({position: {lat: 36.883707, lng: 30.689216}, title:"Ataturk Park"}),
            // new google.maps.Marker({position: {lat: 36.885233, lng: 30.702323}, title:"Oldtown"}),
            // new google.maps.Polygon({paths: [
            //     {lat: 36.9177, lng: 30.7854},{lat: 36.8851, lng: 30.7802},{lat: 36.8829, lng: 30.8111},{lat: 36.9177, lng: 30.8159}
            // ], strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35
            // }),
            // new google.maps.Circle({center: {lat: 36.90707, lng: 30.56533}, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500}),
            // new google.maps.Polyline({path: [{lat: 36.86149, lng: 30.63743},{lat: 36.86341, lng: 30.72463}], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2})
        ]);
    };
    //map marker 2
    const addPolyline2 = (list) => {
        let newPolyline = new google.maps.Polyline({
            path: list,
            geodesic: true,
            strokeColor: "#00FF00",
            strokeOpacity: 0.5,
            strokeWeight: 2,
        });
        setOverlays((prev) => [...prev, newPolyline]);
    };
    const addMarkerPassengerStart = (lat, lng, title, green = false) => {
        // //console.log(lat, lng, title);
        let newMarker = new google.maps.Marker({
            position: {
                lat: lat,
                lng: lng,
            },
            title: title,
            draggable: false,
            icon: !green
                ? null
                : {
                      path: "m 12,2.4000002 c -2.7802903,0 -5.9650002,1.5099999 -5.9650002,5.8299998 0,1.74375 1.1549213,3.264465 2.3551945,4.025812 1.2002732,0.761348 2.4458987,0.763328 2.6273057,2.474813 L 12,24 12.9825,14.68 c 0.179732,-1.704939 1.425357,-1.665423 2.626049,-2.424188 C 16.809241,11.497047 17.965,9.94 17.965,8.23 17.965,3.9100001 14.78029,2.4000002 12,2.4000002 Z",
                      fillColor: "#00FF00",
                      fillOpacity: 1.0,
                      strokeColor: "#000000",
                      strokeWeight: 1.5,
                      scale: 1.8,
                      anchor: new google.maps.Point(12, 24),
                  },
        });

        setOverlays((prev) => [...prev, newMarker]);
        // Create an info window
        const infoWindow = new window.google.maps.InfoWindow({
            content: `
            <div>
            <p><b>Passenger</b></p>
            <p><b>Name: </b>${ride?.accepted?.userId?.firstName.charAt(0).toUpperCase() + ride?.accepted?.userId?.firstName.slice(1)} ${ride?.accepted?.userId?.lastName.charAt(0).toUpperCase() + ride?.accepted?.userId?.lastName.slice(1)}</p>
            <p><b>Pickup Location:</b>${title}</p>
          </div>
            `,
        });

        const func = () => {
            return <div></div>;
        };

        // Open the info window on marker hover
        newMarker.addListener("mouseover", () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener("mouseout", () => {
            infoWindow.close();
        });
    };

    const addMarkerPassengerEnd = (lat, lng, title, green = false) => {
        // //console.log(lat, lng, title);
        let newMarker = new google.maps.Marker({
            position: {
                lat: lat,
                lng: lng,
            },
            title: title,
            draggable: false,
            icon: !green
                ? null
                : {
                      path: "m 12,2.4000002 c -2.7802903,0 -5.9650002,1.5099999 -5.9650002,5.8299998 0,1.74375 1.1549213,3.264465 2.3551945,4.025812 1.2002732,0.761348 2.4458987,0.763328 2.6273057,2.474813 L 12,24 12.9825,14.68 c 0.179732,-1.704939 1.425357,-1.665423 2.626049,-2.424188 C 16.809241,11.497047 17.965,9.94 17.965,8.23 17.965,3.9100001 14.78029,2.4000002 12,2.4000002 Z",
                      fillColor: "#00FF00",
                      fillOpacity: 1.0,
                      strokeColor: "#000000",
                      strokeWeight: 1.5,
                      scale: 1.8,
                      anchor: new google.maps.Point(12, 24),
                  },
        });

        setOverlays((prev) => [...prev, newMarker]);
        // Create an info window
        const infoWindow = new window.google.maps.InfoWindow({
            content: `
               <div>
               <p><b>Passenger</b></p>
               <p><b>Name: </b>${ride?.accepted?.userId?.firstName.charAt(0).toUpperCase() + ride?.accepted?.userId?.firstName.slice(1)} ${ride?.accepted?.userId?.lastName.charAt(0).toUpperCase() + ride?.accepted?.userId?.lastName.slice(1)}</p>
               <p><b>End Location: </b>${title}</p>
            </div>
               `,
        });

        const func = () => {
            return <div></div>;
        };

        // Open the info window on marker hover
        newMarker.addListener("mouseover", () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener("mouseout", () => {
            infoWindow.close();
        });
    };

    return (
        <>
            {loading === true || loading === undefined ? (
                <div>
                    <CustomLoading />
                </div>
            ) : (
                <div style={{ width: "100%" }}>
                    <Dialog draggable={false} resizable={false} position="center" className="text-center" visible={showReceiptDialog} breakpoints={{ "960px": "75vw" }} style={{ width: "40vw" }} onHide={() => onHide("showReceiptDialog")}>
                        <div>{receiptId != null && <ReceiptDialog permissions={permissions} receiptId={receiptId} />}</div>
                    </Dialog>
                    {googleMapsReady && (
                        <div className="card">
                            <GMap
                                ref={(ref) => {
                                    mapRef = ref;
                                }}
                                overlays={overlays}
                                // options={options}
                                style={{ width: "100%", minHeight: "320px" }}
                                onMapReady={onMapReady}
                                onMapClick={onMapClick}
                                onOverlayClick={onOverlayClick}
                                onOverlayDragEnd={handleDragEnd}
                            />
                        </div>
                    )}

                    <div className="card">
                        <h3>Passenger</h3>
                        <DataTable responsiveLayout="scroll" value={[ride?.userId]} key="passenger" emptyMessage="No record found.">
                            <Column className="maker_colm"
                                header="Image"
                                body={(data) => {
                                    return (
                                        <div>
                                            <Image width="50px" preview template="Preview" src={`${appUrl.fileBaseUrl}${data?.profileImage}`} />
                                        </div>
                                    );
                                }}
                            />
                            <Column className="maker_colm" field="firstName" header="First name" />
                            <Column className="maker_colm" field="lastName" header="Last name" />
                            <Column className="maker_colm" field="mobile" header="Mobile" />
                            <Column className="maker_colm" field="gender" header="Gender" />
                            <Column className="maker_colm"
                                body={(data) => (
                                    <div>
                                        {data?.status === "completed" && (
                                            <Button
                                                tooltip="View Receipts"
                                                icon="pi pi-money-bill"
                                                tooltipOptions={{ position: "top" }}
                                                className="edit"
                                                onClick={() => {
                                                    // //console.log(data._id);
                                                    setReceiptId(data._id);
                                                    onClick("showReceiptDialog");
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                                header="Receipt"
                            />
                        </DataTable>
                        {ride?.accepted !== null && (
                            <>
                                <h3>Owner</h3>
                                <DataTable responsiveLayout="scroll" value={[ride?.accepted?.userId]} key="driver">
                                    <Column className="maker_colm"
                                        header="Image"
                                        body={(data) => {
                                            return (
                                                <div>
                                                    <Image width="50px" preview template="Preview" src={`${appUrl.fileBaseUrl}${data?.profileImage}`} />
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column className="maker_colm" field="firstName" header="First name" />
                                    <Column className="maker_colm" field="lastName" header="Last name" />
                                    <Column className="maker_colm" field="mobile" header="Mobile" />
                                    <Column className="maker_colm" field="gender" header="Gender" />
                                </DataTable>
                                <h3>Vehicle</h3>
                                <DataTable responsiveLayout="scroll" value={[ride?.accepted?.vehicleId]} key="vehicle">
                                    <Column className="maker_colm" field="model.model" header="Model" />
                                    <Column className="maker_colm" field="model.make.make" header="Maker" />
                                    <Column className="maker_colm" field="model.seatingCapacity" header="Seating Capacity" />
                                    <Column className="maker_colm" field="year" header="Year" />
                                    <Column className="maker_colm" field="registrationNumber" header="No." />
                                    <Column className="maker_colm" field="registrationCity.city" header="City" />
                                    <Column className="maker_colm" field="registrationProvince.name" header="Province" />
                                    <Column className="maker_colm" field="minMileage" header="Average Mileage(Km)" />
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
