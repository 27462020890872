import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_filed";
import { addFaqsCategory, editFaqsCategory, resetAddEditFaqCategory } from "../../../../../redux/slices/faqs_category_slice";
import { getFaqsList } from "../../../../../redux/slices/faqs_slice";

export default function AddEditCategory({ onHide, editData, permissions }) {
    const faqsReducer = useSelector((state) => state.faqCategory);
    const { addLoading, addError, editLoading, addSuccess, editSuccess, editError } = faqsReducer;
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Name is required").test(
                "is-valid-comment",
                "Comment cannot be empty or only spaces",
                (value) => value && value.trim().length > 0
            ),
    });

    useEffect(() => {
        if (editData !== null) {
            formik.setFieldValue("name", editData.categoryName);
        }
    }, []);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
        },

        onSubmit: async (data) => {
            if (editData === null) {
                dispatch(addFaqsCategory(data));
            } else {
                data["categoryId"] = editData._id;
                dispatch(editFaqsCategory(data));
            }
        },
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Category successfully added");

                onHide();
                dispatch(getFaqsList());
            } else {
                toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Category successfully updated");

                onHide();
                dispatch(getFaqsList());
            } else {
                toast.error(addError);
            }
        }

        return () => {
            dispatch(resetAddEditFaqCategory());
        };
    }, [addSuccess, editSuccess, addError, editError]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                        <div className="col-12">
                            <CustomInputField iden="name" label="Category" formik={formik} type="text" />
                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                {permissions?.add && editData == null && <Button loading={editData === null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}
                                {permissions?.edit && editData && <Button loading={editData === null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
