import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getCorporateCodesList, addEditCorporate, getGoogleAddress } from "../../../../../redux/slices/AddCorporateSlice";
import { getGoogleAddress } from "../../../../../redux/slices/AddCorporateSlice";

import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
// import { addNewCorporate, editCorporate } from "../../../../../redux/slices/AddCorporateSlice";
import appUrl from "../../../constants/appUrl";
import { axiosApi } from "../../../constants/axios_api";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
function AddCorporateDialog({ onHideCorporateDialog, editCode, permissions, corporateData, editable, getTableData, getCorporateCodesList }) {
    const corporateReducer = useSelector((state) => state.newCorporateCode);
    const corporateCodeOldReducer = useSelector((state) => state.corporateCode);
    const modifiedLocations = corporateCodeOldReducer?.address?.map((location, index) => ({ id: index, title: location }));
    // const { addLoading, addError, editLoading, editError, addSuccess, editSuccess } = corporateReducer;
    const [selectedAddress, setSelectedAddress] = useState("");
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingIcon, setLoadingIcon] = useState("");
    const [fields, setFields] = useState([{ value: "" }]);
    const [oldDomains, setOldDomains] = useState([]);
    const [newDomains, setNewDomains] = useState([{ value: "" }]);
    const [imageUrl, setImageUrl] = useState(null);
    const [newImageSelected, setNewImageSelected] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [addressValid, setAddressValid] = useState(true);
    // Tracks if a new image has been chosen

    const dispatch = useDispatch();
    const formikRef = React.useRef();

    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];
    // Corporate Img handler
    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();

    //     reader.onload = (e) => {
    //         setImageUrl(e.target.result);
    //     };

    //     reader.readAsDataURL(file);
    //     formik.setFieldValue("image", file);
    //     formik.setFieldTouched("image", true); // Mark the image field as touched
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Check if file exists and is an image
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setImageUrl(e.target.result);
                formik.setFieldValue("image", file);
                formik.setFieldTouched("image", true); // Mark the image field as touched
                setNewImageSelected(true); // Set new image selected flag
                setButtonDisable(false); // Reset the flag if invalid image
            };

            reader.readAsDataURL(file);
        } else {
            toast.error("Please upload a valid image file.");
            setNewImageSelected(false); // Reset the flag if invalid image
            setButtonDisable(true); // Reset the flag if invalid image
            setImageUrl(null); // Clear the image preview if invalid
            formik.setFieldValue("");
        }
    };

    const handleAddField = (index) => {
        // Insert a new field just below the current field
        const newFields = [...fields];
        newFields.splice(index + 1, 0, { value: "" });
        setFields(newFields);
    };

    const handleRemoveField = (index) => {
        if (fields.length > 1) {
            const fieldToRemove = fields[index];
            // If the domain being removed is an old one (i.e., it has an _id), remove its _id from oldDomains
            if (corporateData?.data?.domains?.some((domainObj) => domainObj.domain === fieldToRemove.value)) {
                const removedDomainObj = corporateData?.data?.domains.find((domainObj) => domainObj.domain === fieldToRemove.value);
                setOldDomains((prevOldDomains) => {
                    const updatedOldDomains = prevOldDomains.filter((oldDomainId) => oldDomainId !== removedDomainObj._id);
                    return updatedOldDomains;
                });
            }
            // Remove the field from the UI
            const newFields = fields.filter((_, idx) => idx !== index);
            setFields(newFields);
        }
    };

    const handleValueChange = (index, event) => {
        const newFields = [...fields];
        const newValue = event.target.value;

        // Check for duplicate domain
        if (newFields.some((field, idx) => field.value === newValue && idx !== index)) {
            toast.error("Duplicate domain names are not allowed.");
            return;
        }

        newFields[index].value = newValue; // Update the specific field's value
        setFields(newFields);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-Z0-9](?!.*\s$)[a-zA-Z0-9\s]*$/, "Name cannot contain special characters and must not start or end with spaces.")
            .required("This field is required."),
        address: Yup.string().required("This field is required."),
        image: Yup.string().required("This field is required."),
        domains: Yup.array()
            .of(
                Yup.string()
                    .required("This field is required.")
                    .matches(
                        /^(?!-)(?!.*--)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,})+$/, // Simple regex for basic domain validation
                        "Domain name is not valid."
                    )
            )
            .min(1, "At least one domain is required."), // Ensure at least one domain is provided
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            address: "",
            domains: fields.map((field) => field.value),
            logo: imageUrl,
        },

        onSubmit: async (data) => {
            setLoading(true);
            setLoadingIcon("pi pi-spinner");

            // Validate address
            if (!selectedAddress || !addressValid) {
                toast.error("Please select a valid address.");
                setLoading(false);
                setLoadingIcon("");
                return;
            }

            // Map fields to domain values (either strings or _id)
            const allDomains = fields.map((field) => field.value);

            // Separate old domains (which have _id) and new domains (which are strings)
            const existingDomainsIds = corporateData?.data?.domains?.map((domainObj) => domainObj._id) || [];

            const newDomains = allDomains.filter((domain) => !corporateData?.data?.domains.some((existingDomain) => existingDomain.domain === domain));

            const obj = {
                address: selectedAddress,
                name: data.name,
                active: data.active,
                domains: newDomains, // New domain values (strings)
                image: imageUrl, // Base64-encoded image
            };

            if (!editable) {
                // Validate image format for adding
                if (!imageUrl || !imageUrl.startsWith("data:image")) {
                    toast.error("Please upload a valid image file before submitting.");
                    setLoading(false);
                    setLoadingIcon("");
                    return; // Exit function if validation fails
                }

                try {
                    const response = await axiosApi.post(`${appUrl?.baseUrl}${appUrl?.addNewCorporate}`, obj);
                    if (response?.data?.statusCode === 200) {
                        await dispatch(
                            getCorporateCodesList({
                                pageNumber: 0,
                                text: "",
                                perPageRecords: 50,
                            })
                        );
                        toast.success("Corporate Added successfully");
                        onHideCorporateDialog();
                        // window.location.reload();
                    }
                } catch (error) {
                    toast.error(error);
                    onHideCorporateDialog();
                }
            } else {
                const allDomains = fields.map((field) => field.value); // Get current input domain values

                // Existing domain information
                const existingDomains = corporateData?.data?.domains || [];
                const existingDomainMap = Object.fromEntries(existingDomains.map((domain) => [domain.domain, domain._id]));

                // Prepare arrays for new domains and old domains
                const oldDomainsToKeep = [];
                const newDomainsToAdd = [];

                allDomains.forEach((domain) => {
                    if (existingDomainMap[domain]) {
                        // If the domain exists, keep the old ID for update
                        oldDomainsToKeep.push(existingDomainMap[domain]); // _id of the existing domain
                    } else {
                        // If the domain doesn't exist, it's a new domain
                        newDomainsToAdd.push(domain);
                    }
                });
                // If image is not changed, send an empty string when updating
                let imageToSend = imageUrl;
                if (editable && imageUrl === appUrl?.baseUrl + "/" + corporateData?.data?.logo) {
                    imageToSend = ""; // If the image hasn't changed, send an empty string
                }

                // Validate the image only if a new one has been selected
                if (newImageSelected && (!imageUrl || !imageUrl.startsWith("data:image"))) {
                    toast.error("Please upload a valid image file before submitting.");
                    setLoading(false);
                    setLoadingIcon("");
                    return;
                }

                const obj = {
                    address: selectedAddress,
                    name: data.name,
                    active: data.active,
                    oldDomains: oldDomainsToKeep, // Send only old domain _id's
                    domains: newDomainsToAdd, // Send only new domains (values)
                    image: imageToSend, // Update the logo image
                };

                try {
                    const response = await axiosApi.patch(`${appUrl?.baseUrl}${appUrl?.editCorporatebyId}/?id=${corporateData?.key}`, obj);

                    if (response?.data?.statusCode === 200) {
                        await dispatch(
                            getCorporateCodesList({
                                pageNumber: 0,
                                text: "",
                                perPageRecords: 50,
                            })
                        );
                        toast.success("Corporate updated successfully");
                        onHideCorporateDialog();
                        // window.location.reload();
                    }
                } catch (error) {
                    toast.error(error);
                    onHideCorporateDialog();
                }
            }
        },
    });

    useEffect(() => {
        formikRef.current = formik;
    }, [formik]);
    useEffect(() => {
        formik.setFieldValue(
            "domains",
            fields.map((field) => field.value)
        );
    }, [fields]);

    const loadInitialValues = () => {
        formik.setFieldValue("name", corporateData?.data?.name);
        formik.setFieldValue("active", corporateData?.data?.active);
        setSelectedAddress(corporateData?.data?.address || "");
        setSearchText(corporateData?.data?.address || "");
        formik.setFieldValue("address", corporateData?.data?.address);
        formik.setFieldValue("image", corporateData?.data?.logo);
        // Check if corporateData has domains and set it to fields state
        if (corporateData?.data?.domains?.length) {
            // Map through the domains array to get the domain values
            const domainsFields = corporateData.data.domains.map((domainObj) => ({
                _id: domainObj._id, // Keep the _id (if needed later)
                value: domainObj.domain, // Extract the domain value
            }));

            setFields(domainsFields); // Set the correct fields for further use
            formik.setFieldValue("domains", domainsFields);
        } else {
            console.log("No domains found in the corporate data");
        }
        // Set oldDomains to contain only the _id values
        if (corporateData?.data?.domains?.length) {
            const oldDomainsIds = corporateData.data.domains.map((domainObj) => domainObj._id); // Store _id only
            setOldDomains(oldDomainsIds);
            const domainsFields = corporateData.data.domains.map((domainObj) => ({
                value: domainObj.domain, // Set new domain field values
            }));
            setNewDomains(domainsFields); // Initialize new domains state
        } else {
            setNewDomains([{ value: "" }]);
        }

        // Set the logo image URL if it's available in the corporate data
        if (corporateData?.data?.logo) {
            setImageUrl(appUrl?.baseUrl + "/" + corporateData.data.logo); // Set image URL for preview
        }
    };

    useEffect(() => {
        if (editable) {
            loadInitialValues();
        }
    }, [corporateData, editable]);

    const handleInputChange = (text) => {
        setSelectedAddress(text);
        setSearchText(text);
        dispatch(getGoogleAddress({ text })).then((response) => {
            if (response.payload.length === 0) {
                setAddressValid(false);
            } else {
                setAddressValid(true);
            }
        });
        if (formikRef.current) {
            formikRef.current.setFieldValue("address", text);
        }
    };

    const handleOnSelect = (item) => {
        setSelectedAddress(item.title);
        setSearchText(item.title);
        setAddressValid(true);
        if (formikRef.current) {
            formikRef.current.setFieldValue("address", item.title);
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="name" formik={formik} type="text" />
                </div>
                <div className="p-fluid col-12 md:col-6" style={{ padding: "0 30px" }}>
                    <p>Address</p>
                    <ReactSearchAutocomplete
                        items={modifiedLocations}
                        fuseOptions={{ keys: ["title"] }}
                        resultStringKeyName="title"
                        onSearch={handleInputChange}
                        onSelect={handleOnSelect}
                        inputSearchString={searchText}
                        showIcon={false}
                        styling={{
                            height: "34px",
                            borderRadius: "4px",
                            backgroundColor: "white",
                            boxShadow: "none",
                            hoverBackgroundColor: "lightgreen",
                            color: "darkgreen",
                            fontSize: "12px",
                            border: "1px solid #ced4da",
                            fontFamily: "Courier",
                            iconColor: "green",
                            lineColor: "lightgreen",
                            placeholderColor: "darkgreen",
                            clearIconMargin: "3px 8px 0 0",
                            zIndex: 2,
                        }}
                    />
                    <div>
                        {formik.touched.address && formik.errors.address && (
                            <small className="p-error">{formik.errors.address}</small> // Show error message if invalid
                        )}
                        {!addressValid && <small className="p-error">Invalid address.</small>}
                    </div>
                </div>
                {editable && (
                    <div className="p-fluid col-12 md:col-6 w-100">
                        <div className="field w-100">
                            <label>Active</label>
                            <Dropdown
                                className="col-12 m-0 p-0 w-100"
                                value={formik.values.active} // current selected value
                                onChange={(e) => formik.setFieldValue("active", e.value)} // update value on change
                                options={statusList}
                                optionLabel="name"
                                optionValue="code"
                                placeholder="Select Status"
                            />
                            {formik.touched.active && formik.errors.active && <small className="p-error">{formik.errors.active}</small>}
                        </div>
                    </div>
                )}
                {/* add domain field */}
                <div className="p-fluid col-12 md:col-6 w-50" style={{ padding: "0px 38px" }}>
                    <label style={{ paddingBottom: "10px" }}>Domain</label>
                    {fields.map((field, index) => (
                        <div key={index} className="mb-2 w-100">
                            <div className="flex align-items-center pt-2 w-100">
                                <InputText
                                    id={`domain-${index}`}
                                    type="text"
                                    placeholder="Please Enter Domain"
                                    value={field.value}
                                    onChange={(e) => {
                                        handleValueChange(index, e);
                                        formik.setFieldTouched(`domains[${index}]`, true); // Mark as touched on change
                                    }}
                                    className={`mr-2 ${formik.touched.domains && formik.errors.domains && formik.errors.domains[index] ? "p-invalid" : ""}`} // Add error class if invalid
                                    style={{ width: "100" }}
                                />
                                <Button
                                    type="button"
                                    onClick={() => handleAddField(index)}
                                    icon="pi pi-plus"
                                    className="ml-2 p-button-success"
                                    disabled={
                                        formik.errors.domains && formik.errors.domains[index] // Disable button if the current field has an error
                                    }
                                />
                                {fields.length > 1 && <Button type="button" onClick={() => handleRemoveField(index)} icon="pi pi-minus" className="ml-2 p-button-danger" />}
                            </div>
                            <div>
                                {formik.touched.domains && formik.errors.domains && formik.errors.domains[index] && (
                                    <small className="p-error">{formik.errors.domains[index]}</small> // Show error message if invalid
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className="p-fluid col-12 md:col-6 w-50"
                    style={{
                        padding: "0px 30px",
                        marginLeft: editable ? "1%" : "0", // Apply marginLeft conditionally on the parent div
                    }}
                >
                    <label style={{ paddingBottom: "20px" }}>Logo</label>
                    <InputText
                        id="image"
                        style={{
                            marginTop: "7px",
                            width: editable ? "96%" : "100%",
                        }}
                        placeholder="Upload Profile Image"
                        name="image"
                        type="file"
                        accept=".jpeg, .jpg, .png, .svg"
                        onChange={handleFileChange}
                    />
                    {formik.touched.image && formik.errors.image && <small className="p-error">{formik.errors.image}</small>}
                    {imageUrl && <img src={imageUrl} alt="Logo Preview" width="100" height="100" />}
                </div>

                <div className="p-fluid col-12">
                    <div className="button_class-modal">
                        <Button disabled={buttonDisable || loading ? true : false} icon={loadingIcon} iconPos="left" type="submit" label={editable ? "Update" : "Add"} className="p-button-success btn__default_modal p-button-rounded" />
                    </div>
                </div>
            </div>
        </form>
    );
}
export default AddCorporateDialog;
