import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import AddEditCategory from "./add_edit_category";
import AddEditFaqs from "./add_edit_faqs";
import CategoryItem from "../components/category_item";
import "primeicons/primeicons.css";
import { getFaqsList, resetAddEditFaq } from "../../../../../redux/slices/faqs_slice";
import { deleteFaqsCategory } from "../../../../../redux/slices/faqs_category_slice";
import CustomLoading from "../../../components/custom_loading";
import { toast } from "react-toastify";
import CustomPageHeader from "../../../components/custom_page_header";

export default function FaqsScreen({ permissions }) {
    const dispatch = useDispatch();
    const { data: list, loading, deleteSuccess, deleteError } = useSelector((state) => state.faqsList);
    const [editCategory, setEditCategory] = useState(null);
    const [editFaq, setEditFaq] = useState(null);
    const [showAddEditCategoryDialog, setAddEditCategoryDialog] = useState(false);
    const [showAddEditFaqDialog, setShowAddEditFaqDialog] = useState(false);
    const dialogFuncMap = {
        showAddEditCategoryDialog: setAddEditCategoryDialog,
        showAddEditFaqDialog: setShowAddEditFaqDialog,
    };

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    useEffect(() => {
        if (list === undefined) {
            dispatch(getFaqsList());
        }
    }, []);
    useEffect(() => {
        if (deleteSuccess !== undefined) {
            if (deleteSuccess === true) {
                toast.success("FAQ deleted");
                dispatch(getFaqsList());
            } else {
                toast.error(deleteError);
            }
        }
        return () => {
            dispatch(resetAddEditFaq());
        };
    }, [deleteSuccess, deleteError]);

    return (
        <>
            {loading === true || list === undefined ? (
                <CustomLoading />
            ) : (
                <div className="accordion-demo">
                    <div className="card">
                        <Dialog draggable={false} resizable={false} header={editCategory == null ? "Add Category" : "Update Category"} visible={showAddEditCategoryDialog} onHide={() => onHide("showAddEditCategoryDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                            <AddEditCategory permissions={permissions} editData={editCategory} onHide={() => onHide("showAddEditCategoryDialog")} />
                        </Dialog>

                        <Dialog draggable={false} resizable={false} header={editFaq == null ? "Add FAQs" : "Update FAQs"} visible={showAddEditFaqDialog} onHide={() => onHide("showAddEditFaqDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "70vw" }}>
                            <AddEditFaqs permissions={permissions} editData={editFaq} onHide={() => onHide("showAddEditFaqDialog")} />
                        </Dialog>
                        <div className="pb-5">
                            <div className="grid">
                                <div className="col-12 md:col-6">
                                    <CustomPageHeader title={"FAQs"} />
                                </div>
                                <div className="col-12 md:col-6 text-right">
                                    {permissions?.add && (
                                        <Button
                                            label="Add Category"
                                            type="submit"
                                            className="mr-3 p-button-success p-button-rounded p-button-sm custom___width mb_width"
                                            onClick={() => {
                                                setEditCategory(null);
                                                onClick("showAddEditCategoryDialog");
                                            }}
                                        />
                                    )}
                                    {/* {permissions?.add && ( */}
                                    {(
                                        <Button
                                            className="p-button-success p-button-rounded p-button-sm custom___width mb_width"
                                            label="Add FAQS"
                                            type="submit"
                                            onClick={() => {
                                                setEditFaq(null);
                                                onClick("showAddEditFaqDialog");
                                            }}
                                        />
                                    )}
                                </div>
                                
                            </div>
                        </div>
                        <div className=" mt-5">
                            {list.map((cat, index) => {
                                return (
                                    <div key={cat._id} className="card">
                                        <div className="grid">
                                            <div className="col-12">
                                                <CategoryItem
                                                    permissions={permissions}
                                                    title={cat.categoryName}
                                                    cat={cat._id}
                                                    faqs={cat.FAQs}
                                                    deleteOnClick={() => {
                                                        dispatch(deleteFaqsCategory(cat));
                                                    }}
                                                    categoryOnClick={() => onClick("showAddEditCategoryDialog")}
                                                    onSelectedCategory={() => {
                                                        setEditCategory(cat);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
